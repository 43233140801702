.mappingContainer {
  display: flex;
  align-items: center;

  margin-bottom: 12px;

  &:last-child {
    padding-bottom: 0;
  }
}

.remove {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .icon {
    height: 24px;
    width: 24px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.groupContainer {
  background: var(--background-color);
  padding: 12px;
  border-radius: 4px;
  width: 100%;
}

.inputsGroup {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  max-width: calc(100% - 48px);
}

.inputsWithActionsGroup {
  display: flex;
  gap: 12px;
}

.boxContainer {
  background: transparent;
}