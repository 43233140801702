.container {
    font-size: 14px;
    color: var(--main-text);
    line-height: 1;
    position: relative;

    :global {
        .chips {
            &__value-container {
                padding: 2px 4px;
                min-height: 30px;
                gap: 3px;
                overflow: visible;
                max-width: 100%;

                > div {
                    overflow: hidden;
                }
            }

            &__control {
                outline: none;
                cursor: pointer;
                box-shadow: none;
                min-height: 32px;

                border-radius: 4px;
                width: 100%;
                display: flex;
                align-items: center;
                border: solid 1px var(--border-separator);
                transition: none;

                &--is-focused {
                    border-color: var(--primary);
                    &:hover {
                        border-color: var(--primary) !important;
                    }
                }

                &:hover {
                    border-color: var(--select-hover);
                }
            }

            &__multi-value {
                height: 22px;
                margin:0;

                border-radius: 6px;
                border: solid 1px var(--select-hover);
                background-color: var(--select-hover);

                display: flex;
                align-items: center;

                &__label {
                    font-size: 14px;
                    line-height: 1;
                    color: var(--main-text);
                    padding: 4px 4px 4px 8px;
                    overflow: hidden;
                }

                &__remove {
                    height: 100%;

                    background-color: transparent !important;
                }
            }
        }
    }
}

.removeIcon {
    height: 14px;
    width: 8px;
    margin-right: 6px;
    cursor: pointer;
    transition: fill 0.12s linear;
    fill: var(--primary);
}

.error {
    :global(.chips) {
        &__control {
            &, &:hover {
                border-color: var(--warning);
            }
        }
    }
}

.tooltip {
    font-size: 12px;
    position: absolute;
    top: -29px;
    border: solid 1px var(--border-separator);
    border-radius: 4px;
    box-shadow: 0 0 12px 0 var(--tooltip-shadow);
    background: var(--white);
    color: var(--warning);
    padding: 6px 8px;
}
