.input {
    width: 64px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    color: var(--main-text);
    font-size: 14px;
    padding: 8px 12px;
    text-align: center;
}