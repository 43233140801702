.collapsibleWrapper {
  flex: 1;
}

.entityPanelContent {
  display: flex;
  align-items: center;
  margin-left: 8px;
  flex: 1 1 auto;

  .entityName {
    flex: 1 1 auto;
    margin-left: 6px;
    color: var(--main-text);
    font-size: 14px;
  }
}

.cancelIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  fill: var(--background-color);

  &:hover {
    fill: var(--warning);
  }
}
