.cancelContact {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;

  padding: 24px 12px 16px;
  border-bottom: 1px solid var(--border-separator);
}

.cancelContactDescription {
  color: var(--secondary-text);
  font-size: 14px;
  margin: 16px 0 0 0;
}