.container {
    position: relative;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;

    .addButton {
        margin-right: 12px;
    }

    p {
        margin: 0;
        font-size: 14px;
        color: var(--secondary-text);
    }
}
