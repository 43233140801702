.indentedStepContent {
  padding: 0 8px;
  & > div + div {
    margin-top: 12px;
  }

  &.withBottomMargin {
    margin-bottom: 12px;
  }

  &.withTopMargin:not(:first-child) {
    margin-top: 12px;
  }

  &.withTopPadding {
    padding-top: 12px;
  }

  &.forceNoTopMargin {
    margin-top: 0;
  }
}
