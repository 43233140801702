.menu {
    position: absolute;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%, 0);
    bottom: 100%;
    background-color: var(--white);
    box-shadow: 0 0 8px 0 var(--border-separator);
    border-radius: 8px;
    white-space: nowrap;

    & > div {
        /* tooltip wrapper */
        display: inline-block;
    }

    .menuItem {
        cursor: pointer;
        border: none;
        background: transparent;
        padding: 0;
        outline: none;
        z-index: 1;

        width: 20px;
        height: 20px;

        &:hover .deleteIcon {
            path {
                fill: var(--warning);
            }
        }
        &:hover .switchIcon {
            path {
                fill: var(--primary);
            }
        }
    }
}
