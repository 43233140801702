.selected {
    display: flex;
    align-items: center;
}

.placeholder {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    opacity: 0.4;
}

.container {
    height: 48px;
    display: flex;
    align-items: center;

    &>* {
      margin-left: 8px;

      &:first-child {
        margin-left: 12px;
      }
    }
}

.callStatus {
    width: 270px;

    :global(.custom-select) {
        &__control {
            outline: none;
            border: 1px solid transparent  !important;
            cursor: pointer;
            box-shadow: none;
            font-size: 14px;

            &--menu-is-open {
                border-color: var(--border-separator) !important;
                border-bottom-color: transparent !important;
            }
        }
    }

}

.phoneNumber {
    height: 30px;
    margin-left: 16px;
    display: flex;
    align-items: center;

    &__icon {
        width: 18px;
        height: 18px;
    }

    .phoneNumberInput {
        & > input {
            outline: none;
            border: none;

            text-align: left;
            padding-left: 8px;
            padding-right: 8px;
            font-size: 14px;
        }
    }
}

.survey_complete {
    color: var(--call-green);
}

.survey_incomplete {
    color: var(--dusty-orange);
}

.cancelled {
    color: var(--warning);
}