.associatedAnswer {
    &:nth-last-child(2) {
        margin-bottom: 12px;
    }
}

.elementWithLabel {
    flex: 1 1 auto;
}

.iconRow {
    display: flex;
    justify-content: flex-end;
}

.deleteButton {
    padding: 0;
}

.entityGrid {
    padding-top: 12px;
    display: grid;
    grid-gap: 12px;

    grid-template-columns: calc(50% - 12px) auto;
}

.entitySpan {
    display: flex;
    align-items: center;
    height: 100%;
    .entityIcon,
    .systemEntityIcon {
        fill: var(--disabled);
    }

    &:hover {
        .entityIcon {
            transition: fill 0.12s linear;
            fill: var(--primary);
        }
    }
}

.spacer {
    margin-left: 12px;
    flex: 1 1 50%;
}
