.input {
    line-height: normal;
    outline: none;

    height: 32px;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    color: var(--main-text);
    background-color: var(--white);
    font-size: 14px;
    padding: 8px;
    text-align: left;

    &:hover {
        border-color: var(--select-hover);
    }

    &:focus {
        box-shadow: none;
        border-color: var(--primary);
    }

    &::placeholder {
        color: var(--disabled);
    }

    &[disabled] {
        background-color: var(--disabled-secondary);
        cursor: not-allowed;
        color: var(--disabled);
    }
}

.error {
    border-color: var(--warning);

    &:hover  {
        border-color: var(--warning);
    }

    &:focus-within, &:active {
        border-color: var(--primary);
    }
}

.container {
    width: 176px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.tooltip {
    font-size: 12px;
    position: absolute;
    top: -32px;
    border: solid 1px var(--border-separator);
    border-radius: 4px;
    box-shadow: 0 0 12px 0 var(--tooltip-shadow);
    background: var(--white);
    color: var(--warning);
    padding: 6px 8px;
}
