* {
  font-family: "Roboto", sans-serif;
  outline: none;
}

textarea {
  &:hover {
    border-color: var(--select-hover);
  }
  
  &:focus, &:active {
    border-color: var(--primary);
  }
}