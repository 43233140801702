.container,
.emptyContainer {
    display: flex;
    align-items: center;

    font-size: 12px;
    font-weight: normal;
    line-height: normal;
}

.emptyContainer {
    position: relative;
}

.emptyStatMessage {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-text);
    font-size: 14px;
}

.button {
    margin: 16px;
}

.play,
.pause,
.disabled {
    width: 32px;
    height: 32px;
}

.play,
.pause {
    cursor: pointer;
}

.time {
    color: var(--secondary-text);
    padding-top: 10px;
}

.timeDisabled {
    padding-top: 22px;
}

.emptyDownload,
.download {
    width: 24px;
    height: 24px;
    margin: 20px 16px 20px 24px;
}

.download {
    cursor: pointer;
}

.progressBar {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    margin: 20px 0 0 0;
}

.progressBackground,
.progressBackgroundDisabled {
    width: 100%;
    height: 100%;
    position: relative;

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 4px;
        top: calc(50% - 1px);
        border-radius: 2px;
    }
}

.progressBackground {
    cursor: pointer;
    &:after {
        background-color: var(--disabled);
    }
}

.progressBackgroundDisabled {
    &:after {
        background-color: var(--disabled-secondary);
    }
}

.progress {
    position: absolute;
    width: 0;
    height: 4px;
    top: calc(50% - 1px);
    left: 0;
    background-color: var(--primary);
    border-radius: 2px;
}

.progressIcon,
.progressIconDisabled {
    position: absolute;
    left: 0;
    top: calc(50% - 7px);
    width: 16px;
    height: 16px;
    background-size: contain;
}

.progressIcon {
    cursor: pointer;

    svg {
        pointer-events: none;
    }
}

.progressWrapper {
    flex-grow: 1;
}
