.badge {
  border-radius: 8px;
  background-color: var(--subtle);
  margin-right: 6px;
  padding: 2px 4px;
  color: var(--secondary-text);
  font-size: 12px;
  font-weight: 500;
}

.small {
  font-size: 10px;
}