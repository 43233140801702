.headerContainer {
  grid-template-columns: 1fr 1fr;
}

.logo {
  margin-right: 16px;
}

.organizationName {
  color: var(--primary);
  font-size: 14px;
}
