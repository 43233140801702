.filters {
    font-size: 14px;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    > div {
        cursor: pointer;
        border-radius: 14px;
        border: solid 1px var(--border-separator);
        background-color: var(--white);
        padding: 5px 12px;
        margin: 4px 6px;

        &.clearAll {
            border: solid 1px var(--primary);
            color: var(--primary);
        }
    }
}

.cross {
    margin-left: 6px;
    height: 8px;
    width: 8px;
}