.container {
    width: 100%;
    height: 100%;
    min-width: 1280px;
    padding: 0 16px;
    margin: 0 auto;
}

.filtersContainer {
    display: flex;
    align-items: center;
    height: 48px;
    background: white;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    margin-bottom: 12px;

    & > * {
        margin-right: 12px;

        &:first-child {
            margin-left: 12px;
            margin-right: 24px;
        }
    }

    :global(.custom-select) {
        &__control {
            outline: none;
            border: 1px solid transparent !important;
            cursor: pointer;
            box-shadow: none;
            font-size: 14px;

            &--menu-is-open {
                border-color: var(--border-separator) !important;
                border-bottom-color: transparent !important;
            }
        }
    }
}

.sentencesList {
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    border-radius: 4px;
    width: 100%;
    margin-right: 16px;
    margin-bottom: 16px;

    :global {
        .rt-table {
            overflow: visible;
        }

        .ReactTable,
        .ReactTable .rt-thead .rt-th,
        .ReactTable .rt-td {
            border: none !important;
        }

        .ReactTable .rt-thead .rt-th > div {
            height: 100%;
        }

        .ReactTable .rt-thead.-header {
            box-shadow: none;
            height: 47px;
        }

        .ReactTable .rt-thead .rt-tr {
            height: 47px;
            border-left: 3px solid transparent;
        }

        .ReactTable .rt-tr-group {
            border-bottom: 1px solid var(--border-separator) !important;
            padding: 0;
            &,
            & > .rt-tr {
                height: 44px;
            }

            &:hover {
                background-color: var(--row-hover);

                & .actions > * {
                    visibility: visible;
                }
            }
        }

        .ReactTable .rt-td {
            padding: 0;
        }
    }
}

.navbar {
    display: flex;
    justify-content: space-between;
}

.toggle {
    cursor: pointer;
}

.questionSelect {
    width: 300px;
}

.header {
    color: var(--primary);
    font-size: 14px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 4px;
}

.cell {
    color: var(--main-text);
    font-size: 14px;
    height: calc(100% - 1px);

    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12px;

    &.selected {
        background: var(--table-selection);
    }
}
