.root {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.associatedAnswers {
    flex: 1 1 auto;

    & > div:first-child > div:first-child > div:first-child {
        margin-bottom: 12px;
    }
}