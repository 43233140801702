.row {
  align-items: center;
}

.description {
  font-size: 14px;
}

.step {
  position: relative;
}