.messageElement {
    display: flex;

    &:last-of-type {
        margin-bottom: 24px;
    }

    & + & {
        margin-top: 24px;
    }
}

.messagesContainer {
    padding: 12px 12px 0 12px;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);

    &.nested {
        padding: 0;
        border: none;
    }

    & + & {
        margin-top: 12px;
    }


    & > button {
        margin-top: 0;
    }
}

.bottomContainer {
    display: flex;
    align-items: center;
    margin: 8px 0;

}

.forceHeight {
    height: 24px;
}