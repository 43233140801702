.input {
    width: 100%;
    color: var(--main-text);
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    overflow: hidden !important;
    border-radius: 4px;
    background-color: var(--white);

    &__input {
        width: 100%;
        border: solid 1px var(--border-separator);
        border-radius: 4px;
        padding: 12px;
        outline: none;
        resize: none;
        overflow: hidden;
        min-height: 78px;

        &:disabled {
            background-color: var(--disabled-secondary) !important;
            cursor: not-allowed;
            color: var(--disabled);
        }
    }

    &__highlighter {
        padding: 12px;
        min-height: 78px;
    }

    &--singleLine &__input, &--singleLine &__highlighter {
        height: 32px;
        min-height: 32px;
        padding: 7px;
    }


    &__suggestions {
        padding-top: 8px;
        background-color: transparent !important;
        z-index: 5 !important;
    }

    &__suggestions__list {
        width: 240px;
        border-radius: 4px;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px var(--border-separator);
        background-color: var(--white);

        max-height: 250px;
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__suggestions__item {
        width: 240px;
        min-height: 32px;
        border-radius: 2px;
        font-size: 14px;
        color: var(--main-text);
        display: flex;
        align-items: center;
        padding: 8px;

        &--focused {
            background-color: var(--select-hover);
        }
    }
}

.mention {
    position: relative;
    background-color: var(--variables-background-color);
    border: none;
    border-radius: 4px;
}

.suggestion {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.wrapper {
    position: relative;

    &.error {
        .input__input {
            border-color: var(--warning);

            &:focus-within, &:active {
                border-color: var(--primary);
            }
        }
    }
}

.addButton {
    position: absolute;
    bottom: -24px;
    right: 8px;
    z-index: 3;

    height: 32px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
    border-color: var(--border-separator);
    background-color: var(--white);
    color: var(--disabled);

    animation: fadeIn 0.17s ease-out;

    cursor: pointer;

    outline: none;
}

@keyframes fadeIn {
    0% {
        transform: scale(0.8);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
