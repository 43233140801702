.form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.description {
  margin: 0;
  color: var(--black);
  font-size: 14px;
  line-height: 20px;
}

.row {
  margin-top: 24px;

  button {
    width: 100%;
  }
}

.separator {
  flex: 1;
}

.resendDescription {
  max-width: 414px;
  font-size: 12px;
  color: var(--disabled);
  margin: 8px 0 0 0;
  line-height:1.5;
}

.resendDescriptionLink {
  font-size: 12px;
  cursor: pointer;
  color: var(--primary);
}
