.body {
  box-sizing: border-box;
  align-items: center;
  box-shadow: 0 2px 12px 0 var(--component-shadow);
  display: flex;
  padding: 12px;
  border-radius: 4px;
  width: 436px;

  &.success {
    border: 1px solid var(--toast-success-border);
    background: var(--notification-success-background);
  }

  &.error {
    border: 1px solid var(--toast-error-border);
    background: var(--notification-error-background);
  }

  &.warning {
    border: 1px solid var(--toast-warning-border);
    background: var(--notification-warning-background);
  }

  & + & {
    margin-top: 16px;
  }
}

.content {
  display: flex;
  align-items: center;
  padding: 0;
  flex: 1;
  font-size: 14px;
  color: var(--black);
}

.closeIcon {
  cursor: pointer;
}

.closeIconWrapper {
  height: 24px;
  background: none;
  border: none;
  padding: 0;
}

:global(.Toastify) {
  height: 0 !important;
}

:global(.Toastify__toast-container) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
