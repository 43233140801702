.silenceFallbackEmptyState {
  display: flex;
  flex-flow: row;
  padding-top: 12px;

  svg {
    min-width: 18px;
    min-height: 18px;
    margin-left: 13.5px;
    margin-right: 6.5px;
  }

  .message {
    font-size: 14px;
    line-height: 20px;
    font-style: italic;
  }

  .buttonContainer {
    margin-top: 6px;
    display: flex;
    flex-flow: row;
    justify-content: center;


  }
}