.header {
  background-color: var(--white);
  height: 57px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: 0 16px;
  align-items: center;
  border-bottom: 1px solid var(--border-separator);

  .linksWrapper {
    height: 100%;
  }

  > div {
    display: inline-flex;
    flex-direction: row;

    &:first-child {
      justify-content: flex-start;
      align-items: center;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
}
