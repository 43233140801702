.verticalSeparator {
    width: 1px;
    background-color: var(--border-separator);
    margin: 12px 0 16px 0;
    z-index: 2;
    flex: 0 0 auto;

    &.noMargin {
        margin: 0;
    }
}
