.sections {
  display: flex;
}

.sectionsNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px var(--border-separator);
  background-color: var(--white);
  padding: 12px;
  width: 100%;
  margin-bottom: 12px;
}

.sectionItem {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: normal;
  color: var(--disabled);
  cursor: pointer;

  & + & {
    margin-left: 24px;
  }

  & > *:first-child {
    margin-right: 4px;
  }

  &.active {
    color: var(--primary);
  }
}

.activeIcon {
  fill: var(--primary);
  stroke: var(--primary);
}
