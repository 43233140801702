.modal {
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    width: 100%;
    height: 100%;

    display: flex;

    justify-content: center;
    align-items: center;
}

.topPosition {
    align-items: flex-start;

    & .content {
        margin-top: 80px;
    }
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-color: var(--black);
}

.content {
    width: 916px;
    height: 400px;
    border: 0;
    background-color: var(--white);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 16px;
    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: center;

    :global {
        animation: fadeIn 150ms linear;
    }

    &.noPadding {
        padding: 0;
    }

    &.noFixedHeight {
        height: auto;
    }

    &.large {
        width: 100%;
        max-width: 1200px;
    }

    &.medium {
        width: 661px;
    }

    &.small {
        width: 392px;
    }
}

.text {
    font-size: 20px;
    margin: 16px 0;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    margin-bottom: 8px;
}

.button {
    width: 128px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px var(--grey-4);
    background-color: var(--white);
    outline: none !important;

    margin-right: 8px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: rgba(205, 205, 205, 0.5) 0 10px 20px;

    &+& {
        margin-left: 16px;
    }
}

.modalHeader {
    font-size: 18px;
    text-align: center;
    width: 100%;
    margin: 24px 0 12px 0;
}

.modalParagraph {
    margin-bottom: 12px;
    margin-top: 0px;
    padding: 0 8px;
    box-sizing: border-box;
    font-size: 14px;
}