.container {
    width: 100%;
    font-size: 14px;
    color: var(--main-text);
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > * {
        margin: 2px 4px 2px 0;
    }
}

.container:hover {
    .synonym {
        transition: opacity 0.15s ease-in-out;
        opacity: 1;
    }
    .synonym:not(:hover) {
        opacity: 0.4;
    }
}

.container__editable {
    :global {
        .chips {
            &__multi-value {
                margin: 2px 4px 2px 0;
                background-color: var(--select-hover);
            }
        }
    }
}

.container__not_editable {
    :global {
        .chips {
            &__multi-value {
                margin: 2px 4px 2px 0;
                padding-right: 4px;
                background-color: var(--border-separator);
            }
        }
    }
}

.removeIcon {
    height: 14px;
    width: 8px;
    margin-right: 6px;
    cursor: pointer;
    transition: fill 0.12s linear;
    fill: var(--primary);
}

.chips {
    display: flex;
    align-items: center;
    border: none;
    font-size: 14px;
    border-radius: 8px;
    margin: 2px 4px 2px 0;
    height: 24px;
    padding-right: 8px;
    padding-left: 8px;
    height: 24px;
    width: fit-content;
    background-color: var(--border-separator);
    color: var(--black);
}

button.chips {
    cursor: pointer;
}

.clickableChips {
    border-radius: 8px;
    margin: 2px 4px 2px 0;
    height: 24px;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 4px;
    width: fit-content;
    background-color: var(--white);
    color: var(--primary);
    cursor: pointer;
}
