.area {
    font-family: Roboto, sans-serif;
    resize: none;
    width: 100%;
    height: 74px;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    font-size: 14px;
    color: var(--main-text);

    padding: 12px;
}

.label {
    display: flex;
    margin-bottom: 8px;

    font-size: 14px;
    color: var(--secondary-text);
}

.limit {
    margin-left: auto;

    font-size: 12px;
    color: var(--primary);
}

.senderInput {
   width: 100%;
   margin-bottom: 16px;
}
