.overlayContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.overlay {
  background: rgba(0, 0, 0, 0.25);
  width: calc(100% - 600px);
}

.questionDetailsContainer {
  width: 600px;
  height: 100%;
  padding: 0 16px 16px 0;
  background: var(--background-color);

  > * {
    height: 100%;
  }
}

.questionDetailsHeader {
  padding: 16px 16px 0 16px;
}

.questionName {
  display: block;
  font-size: 16px;
  margin-bottom: 12px;
}

.questionType {
  display: block;
  font-size: 16px;
  margin-bottom: 0;
  color: var(--primary);
}

.questionDetailsContentWrapper {
  height: 100%;
  overflow-y: scroll;

  > * {
    height: calc(100% - 66px);
  }
}
