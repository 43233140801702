.button, .buttonWithIcon {
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    padding: 7px 12px;
    height: 32px;
    white-space: nowrap;

    &:disabled {
        cursor: not-allowed;

        &:not(.loading) {
            opacity: 0.5;
        }
    }
}

.buttonWithIcon {
    border-width: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px 6px 6px;

    svg {
        margin-right: 4px;
        width: 20px;

        .buttonIcon {
            transition: fill 0.12s linear;
        }
    }
}

.primary {
    background-color: var(--primary);
    border: solid 1px var(--primary);
    color: var(--white);

    .buttonIcon {
        fill: var(--white);
    }
}

.normal {
    background-color: var(--white);
    border: solid 1px var(--primary);
    color: var(--primary);

    .buttonIcon {
        fill: var(--primary);
    }
}

.link {
    background-color: transparent;
    border: none;
    padding: 0;
    color: var(--primary);
    height: auto;

    .buttonIcon {
        fill: var(--primary);
    }
}

.warning {
    background-color: var(--warning);
    border: solid 1px var(--warning);
    color: var(--white);

    .buttonIcon {
        fill: var(--white);
    }
}

.ready {
    background-color: var(--ready);
    border: 1px solid var(--ready);
    color: var(--white);

    .buttonIcon {
        fill: var(--white);
    }
}

.loading {
    background-color: var(--action-hover-subtle);
    border: 1px solid var(--action-hover-subtle);
    cursor: disabled;
}

.linkLoading{
    background-color: transparent;
    border: none;
    cursor: disabled;
}

.loaderWrapper {
    margin: auto;
    width: 76px;
}

.loader {
    margin: 6px 22px 0 22px;
    width: 32px;
    height: 10px;

    span {
        width: 7px;
        height: 7px;
    }
}
