.question {
    margin-top: 16px;

    & > div + div {
        margin-top: 24px;
    }
}

.customStatus {
    margin-top: 12px;
}
