.container {
    border-radius: 4px;
    box-shadow: 0 0 8px 0 var(--component-shadow);
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    z-index: 1;
    cursor: pointer;
}

.containerPosition {
    position: absolute;
    right: 8px;
    top: -10px;
}

.option {
    width: 24px;
    height: 24px;
    object-fit: contain;

    &:not(:last-child) {
        margin-right: 8px;
    }

    &:hover {
        .optionIcon {
            transition: fill 0.12s linear;
            fill: var(--primary);
        }
        .warningIcon {
            fill: var(--warning);
        }
        .confirmIcon {
            fill: var(--call-green);
        }
    }

    &.alignToTop {
        align-self: flex-start;
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
            .confirmIcon {
                fill: var(--disabled);
            }
        }
    }
}

.optionIcon {
    fill: var(--disabled);
    transition: fill 0.12s linear;
}
