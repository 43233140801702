.tile {
  padding: 16px 24px;
  border-radius: 4px;
  min-width: 185px;
  background-color: var(--subtle);
  text-align: right;
  box-sizing: border-box;
}

.title {
  color: var(--text-main);
  font-size: 16px;
  margin-bottom: 8px;
}

.value {
  font-size: 36px;
  font-weight: 500;
  color: var(--primary);
}
