.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border: 1px solid var(--border-separator);
  background: var(--white);
  border-radius: 4px;
  min-height: 128px;
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-text);
  margin: 0;
}
