.trackVertical,
.thumbVertical {
    width: 4px;
    border-radius: 4px;
}
.trackHorizontal,
.thumbHorizontal {
    height: 4px;
    border-radius: 4px;
}

.trackHorizontal {
    margin: 0 4px 4px 4px;
    width: calc(100% - 8px);
    bottom: 0;
    left: 0;
}

.track {
    margin: 4px 4px 4px 0;
    height: calc(100% - 8px);
    top: 0;
    right: 0;
}

.thumb {
    background-color: var(--scrollbar-thumb);
}
