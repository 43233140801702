.popup {
  position: absolute;
  background: var(--white);
  border: solid 1px var(--border-separator);
  font-size: 12px;padding: 6px 8px;
  border-radius: 4px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);

  animation: fade-in 0.3s;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  to {
    opacity: 1;
  }
}