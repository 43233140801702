
.scrollContainer {
    flex-grow: 1;
}

.trainingButtonLoader {
    width: 66px;
    height: 32px;
    background-color: var(--select-hover);
    border: 1px var(--primary);
    border-radius: 4px;
}

.trainingButton {
    align-self: flex-end;
    justify-self: flex-end;
    width: 200px;
    padding: 5px 0;
}

.trainingButtonWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.removeGlobalSentence {
    font-size: 14px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
    width: 90%;
    color: var(--main-text);
}

.lastElement {
    margin-bottom: 16px;
}

.trainingContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}