.content.changePasswordModal {
  box-sizing: border-box;
  width: var(--login-form-standard-width);
  height: 400px;

  &.confirmation {
    height: 337px;
  }

  .form {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
  }

  .input {
    position: relative;
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;

    & > div:first-child {
      height: 16px;
    }

    input {
      padding-top: 9px;
      box-sizing: border-box;
    }
  }

  .buttonsRow {
    justify-content: flex-end;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .confirmationColumn {
    margin: 28px 0 8px 0;
    text-align: center;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    p {
      margin-bottom: 0;
    }
  }
}
