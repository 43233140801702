.event {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px 11px;
    font-size: 14px;
    border-radius: 100vh;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    border-width: 1px;
    border-style: solid;

    border-color: var(--border-separator);
    color: var(--main-text);

    svg {
        margin-right: 8px;
    }
}

.event-technicalInformation {
    border-color: var(--technical-info-event);
    color: var(--technical-info-event);
}