.trainingModal {
    padding: 18px 16px 24px;
}

.trainingModalContent {
    display: grid;
    grid-template-rows: 24px 1fr 32px;

    width: 100%;
    height: 100%;
    grid-gap: 24px;
}

.trainingModalHeader {
    font-size: 18px;
    color: var(--main-text);
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    margin: 0;
}

.trainingModalBody {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 8px;
}

.trainingModalLeft {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 50%;
    padding-right: 13px;

    & > :not(:last-child) {
        margin-bottom: 24px;
    }
}

.trainingModalRight {
    flex-grow: 1;
    flex-basis: 50%;
    padding-left: 13px !important;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.textArea {
    flex-grow: 1;
    height: 100%;
}

.trainingModalButtons {
    display: flex;
    justify-content: flex-end;
    padding: 0 8px;

    & > :not(:last-child) {
        margin-right: 12px;
    }
}
