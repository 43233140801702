.container {
  padding: 16px 24px;
  background: var(--white);
  border: 1px solid var(--border-separator);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  min-height: 118px;
}

.plus {
  width: 40px;
  align-self: center;
  margin: 0 20px 0 40px;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 1;
  height: 100%;
  min-width: 120px;
}

.name {
  color: var(--secondary-text);
  margin-bottom: 12px;
}

.number {
  color: var(--black);
  font-size: 36px;
  font-weight: 500;
  margin-right: 8px;
}

.percentage {
  color: var(--disabled);
  font-size: 16px;
  font-weight: 500;
}

.separator {
  height: 94px;
  width: 40px;
  margin: 0 20px 0 40px;
}

.summary {
  .number {
    color: var(--primary);
  }
}

.wide {
  width: 100%;
}
