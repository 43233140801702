.blueTheme {
    display: flex;
    flex-direction: column;
    width: 208px;
    position: relative;

    .clearIcon {
        margin-left: auto;
    }

    .calendarIcon {
        width: 18px;
        height: 18px;
    }

    .inputWrapper {
        z-index: 11;
        background-color: white;
        display: flex;
    }

    .input {
        outline: none;
        border: none;
        font-size: 14px;
        font-family: inherit;
        min-width: 0;
        padding: 0;
        color: black;
        flex-grow: 1;
        margin-left: 8px;
        &::placeholder {
            color: var(--disabled);
        }
    }

    .pickerContainer {
        z-index: 10;
        position: absolute;
        left: -9px;
        top: 10px;
    }

    .hidden {
        :global {
            .DateRangePicker {
                height: 0;
            }
        }
    }

    .nextMonthIcon,
    .previousMonthIcon {
        position: absolute;
        top: 12px;
    }

    .nextMonthIcon {
        right: 15px;
    }

    .previousMonthIcon {
        left: 15px;
    }

    &.focused {
        :global(.DateRangePickerInput) {
            border-color: var(--border-separator);
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        }
    }

    :global {
        .DateRangePickerInput_calendarIcon,
        .DateInput,
        .DateInput_fang,
        .DateRangePickerInput_arrow {
            display: none;
        }

        .DayPicker__withBorder {
            box-shadow: none;
        }

        .DateRangePicker_picker {
            position: static;
            order: 4;
            border: none;
        }

        .DateRangePicker {
            > div {
                position: absolute;
                top: 0;
                left: 0;
                width: 223px;
            }
        }

        .DateRangePickerInput {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border: 1px solid transparent;
            border-radius: 2px;
        }

        .calendarDay {
            font-size: 12px;
            border-radius: 2px;
            height: 24px;
            width: 24px;
            text-align: center;
            line-height: 24px;
            margin-right: 2px;
            margin-bottom: 2px;
        }

        .CalendarMonthGrid__horizontal {
            left: 0;
        }

        .CalendarMonth {
            padding: 0;

            &_table {
                margin-top: 7px;
            }

            &_caption {
                padding-top: 12px;
                padding-bottom: 41px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: normal;
                color: var(--main-text);
                border-bottom: 1px solid var(--border-separator);

                strong {
                    font-weight: normal;
                }
            }
        }

        .DayPicker {
            &_weekHeader {
                top: 40px;
                padding: 0 !important;

                &_ul {
                    font-size: 12px;
                    color: var(--disabled);
                }

                li {
                    margin-left: 3px;
                }
            }
        }

        .DateInput {
            &__small {
                width: auto;
            }
            &_input {
                width: 80px;
            }
        }

        .CalendarDay {
            border: none;
            background-color: transparent;

            &:hover {
                border: none;
                background: transparent;
            }

            &:hover:not(.CalendarDay__blocked_out_of_range) {
                .calendarDay {
                    color: white;
                    background-color: var(--date-range-picker-focus);
                }
            }

            &:last-child {
                .calendarDay {
                    margin-right: 0;
                }
            }

            &__hovered_span {
                color: var(--main-text);
                .calendarDay {
                    background-color: var(--date-range-background-color-secondary);
                }
            }

            &__selected {
                color: white;

                &_span {
                    .calendarDay {
                        color: var(--main-text);
                        background-color: var(--date-range-background-color-secondary);
                    }
                }
                &_start,
                &_end {
                    .calendarDay {
                        color: white;
                        background-color: var(--date-range-picker-focus);
                    }
                }
            }

            &__outside,
            &__blocked_out_of_range {
                color: var(--disabled);
            }
        }
    }
}

.standardTheme {
    :global {
        .SingleDatePicker {
            .SingleDatePicker_picker {
                z-index: 2;
            }

            .SingleDatePickerInput {
                border-bottom: 1px solid var(--blue);

                .SingleDatePickerInput_calendarIcon {
                    outline: none;
                    padding: 0 4px 4px;
                }

                & .DateInput_input {
                    border-width: 1px;
                }

                & .DateInput_input__focused {
                    border-color: transparent;
                }

                & .DateInput__small > .DateInput_input {
                    padding-top: 5px;
                    padding-bottom: 7px;
                }
            }

            & .CalendarDay.CalendarDay__selected {
                border-color: var(--primary);
                background-color: var(--primary);

                &:hover {
                    background-color: var(--primary);
                }
            }
        }

        .DateInput {
            width: 120px;
        }

        .DateInput:not(.DateInput__small) {
            &_input {
                padding: 7px 11px;
            }
        }

        .DateInput_input {
            font-size: 16px;
            font-weight: 400;
            color: var(--grey-5);
            font-family: 'Roboto', sans-serif;
            cursor: pointer;

            &__focused {
                border-color: var(--blue);
            }
        }

        .CalendarDay__hovered_span {
            border-color: var(--blue);
            background-color: #477c9c80;
            color: white;

            &:hover {
                color: white;
                background-color: rgba(71, 124, 156, 0.8);
            }
        }

        .CalendarDay__selected {
            &_start,
            &_end {
                border-color: var(--blue);
                background-color: var(--blue);

                &:hover {
                    background-color: rgba(71, 124, 156, 0.8);
                }
            }

            &_span:not(&_start, &_end) {
                border-color: var(--blue);
                background-color: rgba(71, 124, 156, 0.6);

                &:hover {
                    background-color: rgba(71, 124, 156, 0.8);
                }
            }
        }

        .DateRangePickerInput_calendarIcon {
            padding: 10px 5px;
            outline: none;
        }

        .DateRangePickerInput_arrow_svg {
            vertical-align: unset;
        }

        .DateInput__small {
            width: 108px;

            & > .DateInput_input {
                padding-left: 9px;
            }
        }
    }

    .calendarIcon {
        display: block;
        height: 15px;
        width: 16px;
        position: relative;

        &::after {
            content: '';
            width: 16px;
            height: 15px;
            background: url('../../images/calendar.svg') no-repeat center;
            background-size: contain;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 12px;
        }
    }
}
