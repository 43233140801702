.wrapper {
  width: 100%;
  margin: 4px 0 4px auto;
  display: flex;
  max-width: 280px;
}

.button {
  background: none;
  border: none;
  padding: 0;
  color: var(--disabled);
  display: flex;
  justify-content: center;
  cursor: pointer;

  svg {
    height: 12px;
    width: 12px;
    margin-right: 4px;
  }

  &:hover,
  &:active {
    color: var(--primary);
  }

  .icon {
    fill: var(--disabled);
  }

  &:hover {
    .icon {
      fill: var(--primary);

      path {
        fill: var(--primary);
      }
    }
  }
}

.text {
  font-size: 12px;
  display: inline-block;
  line-height: 12px;
  text-align: left;
}

.icon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  flex-shrink: 0;
}
