.learning {
  margin-bottom: 30px;
}

:global {

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 30px;
    box-sizing: border-box;
    width: 240px;
    line-height: 10px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

}

.intentLearning {
  display: flex;
  flex-direction: row;
}

.intentLearningInput {
    width: 700px;
}

.button {
  box-sizing: border-box;
  width: 136px;
  height: 40px;
  border-radius: 4px;
  background-color: var(--white);
  border: solid 1px var(--grey-5);
  font-size: 16px;

  cursor: pointer;
}

.status {
  margin-top: 16px;
}

.learningInterpretationKey {
  display: flex;
}

.globalIntents {
  margin: 16px 0;
}

.globalIntentButton {
  color: var(--white);
  border-color: var(--red);
  background-color: var(--red);
}

.globalIntentsContent {
  display: flex;
}

.globalIntentsSelect {
  width: 300px;
}

.multiIntentsForm {
  display: flex;
  flex-direction: column;
  width: 100%;

  & textarea {
    width: 100%;
    height: 250px;
    margin-top: 8px;
  }
}

.multiIntentsButtons {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.closeButton {
  margin-left: auto;
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
  background-color: transparent;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../images/cancel-small.svg');
  cursor: pointer;
}

.popupHeader {
  display: inline-flex;
}