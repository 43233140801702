.variableSelect {
    width: 100%;
}

.selectorContainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 var(--component-shadow);
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    width: 320px;
    z-index: 4;
}

.selectorLabel {
    font-size: 14px;
    color: var(--secondary-text);
    margin-bottom: 8px;
}
