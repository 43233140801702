.container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;
    overflow: hidden;
}

.tagList {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;
    overflow: hidden;
}