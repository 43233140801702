.tagWrapper {
    margin-top: 14px;
    position: relative;

    .tagErrorValidation {
        font-size: 12px;
        position: absolute;
        right: 0;
        border: solid 1px var(--border-separator);
        border-radius: 4px;
        box-shadow: 0 0 12px 0 var(--tooltip-shadow);
        background: var(--white);
        color: var(--warning);
        padding: 6px 8px;
    }


    &.error {
        border-color: var(--warning) !important;
    }
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select {
    line-height: 1;
    font-size: 14px;
    color: var(--main-text);

    :global(.custom-multi-select) {
        &__single-value {
            font-size: 16px;
            line-height: 1;
            color: var(--grey-5);
        }

        &__indicator-separator {
            display: none;
        }

        &__control {
            min-height: 32px;
            transition: none;

            outline: none;
            cursor: pointer;

            border-radius: 4px;
            width: 100%;
            display: flex;
            align-items: center;

            border-width: 1px;
            box-shadow: none;
            border-color: var(--border-separator);

            &:hover {
                border-color: var(--border-separator);
            }

            &--menu-is-open {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &__dropdown-indicator {
            padding: 4px;

            &, &:hover {
                color: var(--grey-4);
            }
        }

        &__menu {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            top: 100%;
            margin: 0;
            box-sizing: border-box;
            border: solid 1px var(--border-separator);
            border-top: 0;
            box-shadow: none;
        }

        &__menu, &__menu-list {
            max-height: 256px;
        }

        &__menu-list {
            padding: 0;
        }


        &__value-container {
            height: 100%;
            padding: 0 0 0 8px;

            &--has-value {
                padding: 2px 0 2px 4px;
            }
        }

        &__multi-value {
            height: 22px;
            margin: 2px 4px 2px 0;

            border-radius: 6px;
            border: solid 1px var(--select-hover);
            background-color: var(--select-hover);

            display: flex;
            align-items: center;

            &__label {
                font-size: 14px;
                line-height: 1;
                color: var(--main-text);
                padding: 4px 4px 4px 8px;
            }

            &__remove {
                height: 100%;

                background-color: transparent !important;
            }
        }

        &__indicators {
            padding: 12px 8px 12px 8px;
            align-items: flex-start;
        }

        &__placeholder {
            opacity: 0.4;
            color: var(--main-text);
        }
    }

    &.error {
        :global(.custom-multi-select) {
            &__control {
                border-color: var(--warning) !important;
            }
        }
    }


}



