input {
    --focus-style: none;
}

.modal__admin {
    width: 580px;
    height: 327px;
    border-radius: 4px;
    background-color: var(--white);
    border-width: 0;
    padding: 0;
}

.modal__notAdmin {
    width: 580px;
    height: 279px;
    border-radius: 4px;
    background-color: var(--white);
    border-width: 0;
    padding: 0;
}

.container {
    width: 100%;
    height: 100%;
    padding: 18px 24px 24px 16px;
}

.title {
    font-size: 18px;
    margin-bottom: 24px;
}

.body {
    margin-left: 8px;
}

.bodyRow {
    display: flex;
    gap: 16px;

    & + & {
        margin-top: 24px;
    }
}

.fieldWrapper {
    width: 50%;
}

.fieldLabel {
    font-size: 14px;
    color: var(--secondary-text);
    margin-bottom: 4px;
}

.fieldInput {
    font-size: 14px;
    width: 260px;
    height: 32px;

    border: solid 1px var(--border-separator);
}

.fieldSelect {
    width: 260px;
    margin-left: auto;
    margin-right: 0;
}

.toggle {
    margin-top: 24px;
    border: none;
    margin-bottom: 24px;
    width: 260px;

    & > div {
        border: none;
        height: 24px;
    }

    & > div > div {
        padding-left: 0;
    }
}

.clientSelect {

    :global(.custom-select) {

        &__control {
            min-height: 32px;
            height: 32px;
        }
    }
}

.bottom {
    width: 100%;
}

.buttons {
    width: fit-content;
    margin-left: auto;
    margin-right: 0;

    * + * {
        margin-left: 12px;
    }

    & > button {
        height: 32px;
        padding: 0 10px;
    }

    &__notAdmin {
        margin-top: 24px;
    }
}

.togglesRow {
    display: flex;
    justify-content: space-between;
}
