.container {
    display: flex;
    margin-top: 12px;
    flex-direction: row;
    justify-content: stretch;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
}

.toggleContainer {
    display: flex;
    align-items: center;
    height: 48px;
    padding-left: 12px;

    & > *:not(:first-child) {
        margin-left: 46px;
    }
}