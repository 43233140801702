.tableControls {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: auto;
    margin-bottom: 12px;
    padding: 12px 14px;

    label:not(:only-child) {
        margin-right: 24px;
    }

    button {
        padding: 0;

        &:not(:last-child) {
            margin-right: 12px
        }

        &:hover {
            .icon {
                fill: var(--primary);
            }
        }

        & .icon {
            transition: fill 0.12s linear;
        }
    }

}

