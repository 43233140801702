.voice {
  & > *:not(:last-child) {
    margin-bottom: 28px;
  }

  .buttonRow {
    margin-top: 24px;
    justify-content: space-between;
    align-items: center;
  }

  a {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--primary);
    text-decoration: none;
  }
}

.playButton {
    padding-left: 8px;
    fill: var(--primary);
}
