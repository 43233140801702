.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  background-color: var(--warning);
  color: var(--white);
  font-size: 10px;
  line-height: 12px;

  &.withChildren {
    padding: 2px 6px;
    border-radius: 16px;
  }
}
