.placeholder {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
}

.icon {
    cursor: pointer;
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
    background: url('../../images/cancel-small.svg') no-repeat center;
}
