button.chip {
  cursor: pointer;
}

.chip {
  display: flex;
  flex-flow: row;
  align-items: center;
  border-radius: 8px;
  padding-left: 8px;
  height: 24px;
  max-height: 24px;
  font-size: 12px;
  width: fit-content;
  user-select: all;

  &.editable {
    background: var(--select-hover);
  }

  &.readOnly {
    border: none;
    padding: 0 8px;
    background-color: var(--border-separator);
    color: var(--black);
  }

  .removeButton {
    cursor: pointer;
    transition: fill 0.12s linear;
    fill: var(--primary);
    border: none;
    background: transparent;
    padding: 0;
    outline: none;
    z-index: 100;

    width: 24px;
    height: 24px;
  }

  .jsonData {
    width: 0;
    height: 0;
    opacity: 0;
    z-index: -1;
  }
}
