.details {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    background-color: var(--white);
    height: 100%;

    .audioRecord {
        border-bottom: 1px solid var(--border-separator);
        flex-shrink: 0;
    }

    .textChatMessage {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 64px;
        color: var(--main-text);
        font-size: 14px;
    }

    .transcript {
        height: calc(100% - 64px);

        > :first-child {
            overflow-x: hidden !important;
        }
    }

    .conversation {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style-type: none;
        overflow: hidden;
        padding: 12px 12px 12px 12px;
        margin: 0;

        .line {
            width: 100%;
            display: flex;
            align-items: center;

            > :first-child {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            &+.line {
                margin-top: 12px;
            }

            .bubble {
                font-size: 14px;
                color: var(--main-text);
                border-radius: 12px;
                padding: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .label {
                font-size: 10px;
                color: var(--secondary-text);
                margin-top: 2px;
            }

            &.human {
                .bubble {
                    max-width: calc(380px - 68px);
                    background-color: var(--conversation-background-odd);
                    border-bottom-left-radius: 0;
                    margin-right: 4px;
                }

                .label {
                    float: left;
                }
            }

            &.bot {
                justify-content: flex-end;
                .bubble {
                    max-width: calc(380px - 100px);
                    align-self: flex-end;
                    background-color: var(--conversation-background-even);
                    border-bottom-right-radius: 0;
                    margin-left: 4px;
                }

                .label {
                    align-self: flex-end;
                    float: right;
                }
            }

            &.notUnderstood>.bubble {
                border: 1px solid var(--border-separator);
                background-color: white;
            }
        }
    }
}

.loadingState,
.emptyState {
    font-size: 14px;
    color: var(--main-text);
    height: 100%;
    text-align: center;
    padding: 208px 12px 12px;
}

.emptyState {
    background-repeat: no-repeat;
    background-image: url('../images/conversations-empty-state.svg');
    background-size: 372px 172px;
    background-position: top center;
    background-position-y: 12px;

    .loader {
        margin: 16px auto 0 auto;
    }
}

.emptyConversation {
    opacity: 0.4;

    >*:first-child {
        width: 272px;
        height: 80px;
    }
}

.jumpToButton,
.reportBugButton {
    opacity: 0;
    transition: opacity 0.12s ease;
    width: 24px;
    display: inline-block;
    height: 24px;
    margin: 0 4px;
    flex-shrink: 0;
    background: transparent;

    svg {
        cursor: pointer;
        width: 100%;
        height: 100%;

        &:hover {
            .animationClass {
                fill: var(--primary);
            }
        }
    }
}

.animationClass {
    fill: var(--disabled);
}

.line:hover {
    .jumpToButton,
    .reportBugButton {
        opacity: 1;
    }
}

.conversationOptionsContainer {
    display: flex;
    padding: 12px;
    border-top: 1px solid var(--border-separator);
}

.showTagsToggle {
    margin-right: 50px;
}

.metadata {
    display: flex;
    flex-flow: row;
    border-bottom: 1px solid var(--border-separator);
    align-items: center;
    font-size: 12px;

    .metadataItem {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        flex: 1 1 auto;
        color: var(--secondary-text);

        &:not(:last-child) {
            .metadataItemText {
                border-right: 1px solid var(--border-separator);
            }
        }
    }

    .metadataItemText {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
    }

    .metadataItemIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;

        svg {
            width: 12px;
            height: 12px;
        }
    }
}

.moduleName {
    color: var(--disabled);
    font-size: 12px;
    display: block;
    width: 100%;
    text-decoration: none;
    margin: 8px 0 4px auto;
    max-width: 280px;

    &:hover {
        color: var(--primary);
    }
}

.questionName {
    color: var(--disabled);
    font-size: 12px;
    display: block;
    width: 100%;
    text-decoration: none;
    margin-bottom: 4px;

    &:hover {
        color: var(--primary);
    }
}