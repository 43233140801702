.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  a {
    color: var(--primary);
    text-decoration: none;
  }

  .separator {
    margin-bottom: 16px;
  }
}

.contentWrapper {
  max-width: 661px;
  margin: auto;
  text-align: center;
}

.text {
  color: var(--secondary-text);
  font-size: 16px;
  margin: 0 0 8px 0;
}

.message {
  margin-bottom: 16px;
}

.linkButton {
  border: 0;
  background: none;
  color: var(--primary);
  cursor: pointer;
  font-size: 16px;
  padding: 0;
}
