.draggableContainer {
  display: flex;

  margin-top: 12px;
  padding: 0 8px;
}

.dragHandle {
  width: 16px;
  min-width: 16px;
  margin: 16px 4px 16px 0;
  border-radius: 4px;
  background-color: var(--border-separator);

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: grab !important;
}
