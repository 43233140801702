.tiles {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px;

  justify-content: start;
}

.sectionTitle {
  color: var(--grey-5);
  font-size: 20px;
}
