.iconButton {
    display: flex;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    border-radius: 9999px;
    margin-right: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    outline: none;

    &.borderPrimary {
        border-color: var(--primary);
    }

    svg {
        width: 16px;
        height: 16px;
        min-width: 16px;
    }
}

.inverted {
    transform: scaleY(-1);
}

.collapsiblePanel {
    display: flex;
    flex-direction: row;
}