.container {
    min-height: 103px;
}


.previewHeader {
    display: flex;
    flex-direction: column;

}
.component {
    width: 320px;
    height: 100%;
    text-decoration: none;
    padding: 12px 8px 8px 12px;
    font-size: 16px;
    line-height: normal;
    color: var(--main-text);
    box-shadow: 0 2px 4px 0 rgba(229, 229, 229, 0.5);
    background-color: var(--white);
    border: solid 1px var(--border-separator);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;

    &.selected {
        border: solid 1px var(--primary);
    }
}

.name {
    font-size: 16px;
}

.options {
    position: absolute;
    right: 8px;
    bottom: 8px;
}

.organization {
    font-size: 14px;
    margin-top: 8px;
    color: var(--primary);
}

.tagsContainer {
    display: flex;
    height: 100%;
    margin-top: 12px;

    flex-wrap: wrap;

    & > * {
        margin-right: 8px;
        margin-bottom: 8px;
    }
}

.nonCustomerVisible {
    .component {
        background: var(--background-disabled-color);
    }

    .name {
        color: var(--disabled);
    }

    .tagsContainer {
        filter: grayscale(1);
    }
}