.report {
    position: relative;
    display: grid;
    grid-template-areas: "title icon"
                          "limit empty";
    grid-template-columns: 1fr auto;

    align-items: center;

    padding: 8px 4px;
    min-height: 40px;
    width: 100%;

    color: var(--main-text);
    border: none;
    background: transparent;
    box-sizing: border-box;
    cursor: pointer;
    text-align: left;
    
    &:not(:last-child) {
        border-bottom: 1px solid var(--border-separator);
    }

    &:disabled {
        cursor: default;
    }

    transition: background-color 0.2s linear;

}

.reportTitle {
    grid-area: title;
}

.conversationsLimit {
    grid-area: limit;
    color: var(--warning);
    font-size: 12px;
    margin-top: 4px;
}

.svgIcon {
    grid-area: icon;
    transition: fill 0.12s linear;
    flex-shrink: 0;
    &:hover {
        path {
            fill: var(--primary);
        }
    }
}

.status.pending {
    color: var(--warning);
}