.description {
  color: var(--grey-4);
  font-size: 12px;
  margin-bottom: 8px;
}

.descriptionContainer {
  padding: 0 12px;
}

.label {
  font-size: 14px;
  color: var(--main-text);
  margin-bottom: 8px;
}
