.callingDays {
    width: 372px;
}

.label {
    color: var(--secondary-text);
    margin-bottom: 8px;
    font-size: 14px;
}

.daysSelect {
    :global(.custom-multi-select) {
        &__control {
            min-height: 32px !important;

            &>div:first-child {
                padding: 0 8px;
            }
        }
    }

}