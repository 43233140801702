.actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;

    & > button, a {
        padding: 0;
        margin-right: 12px;
        visibility: hidden;
    }

    & > button:last-child {
        margin-right: 4px;
    }

    & > button:hover {
        path:last-child {
            fill: var(--primary);
        }
    }

    path:last-child {
        transition: fill 0.12s linear;
    }
}
