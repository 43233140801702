.messageContainer {
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    padding: 12px 12px 12px 12px;

    &.multi {
        padding-bottom: 0;
    }
}

.messageContainer.nested {
    border: none;
    padding: 0;
}

.header {
    display: flex;
    width: 100%;
    align-items: center;
    padding-bottom: 16px;

    &.localIntent {
        flex-flow: column;
    }
}

.label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 24px;
    color: var(--main-text);
    word-wrap: anywhere;

    &.localIntent {
        width: 100%;
        margin-bottom: 8px;
        margin-top: -4px;
    }
}

.condition {
    display: flex;
    margin-left: auto;
    font-size: 14px;
    color: var(--primary);
    cursor: pointer;
    position: relative;
}

.messageElement {
    & + & {
        margin-top: 24px;
    }
}



