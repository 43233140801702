.loginPageContainer {
  display: flex;

  justify-content: center;
  align-items: center;
  flex-flow: column;

  width: 100%;
  height: 100%;

  background: var(--background-color);
}