.specificityLevel.answersList {
  height: 100%;
  margin-top: 0;
}

.answer {
  position: relative;
}

.deleteAnswerButton {
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0;
}

.defaultAnswerGlobalSynonyms {
  &:not(:first-child) {
    &:not(:last-child) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.globalSynonymsChips {
  padding: 4px;
}