input {
    --focus-style: none;
}

.modal__admin {
    width: 580px;
    min-height: 400px;
    height: auto;
    border-radius: 4px;
    background-color: var(--white);
    border-width: 0;
    padding: 0;
}

.modal__notAdmin {
    width: 580px;
    height: 271px;
    border-radius: 4px;
    background-color: var(--white);
    border-width: 0;
    padding: 0;
}

.container {
    width: 100%;
    height: 100%;
    padding: 18px 24px 24px 16px;
}

.title {
    font-size: 18px;
    margin-bottom: 24px;
}

.body {
    margin-left: 8px;
}

.bodyRow {
    display: flex;
}

.botLanguage {
    width: 258px;
    margin-bottom: 16px;
    margin-left: 12px;
}

.botName {
    width: 50%;
    margin-bottom: 8px;
}

.botNameLabel {
    font-size: 14px;
    color: var(--secondary-text);
    margin-bottom: 8px;
}

.newBotLanguage {
    width: 50%;
    margin-bottom: 8px;
}

.clientLabel {
    font-size: 14px;
    color: var(--secondary-text);
    margin-bottom: 8px;
}

.newBotLanguageLabel {
    font-size: 14px;
    color: var(--secondary-text);
    margin-bottom: 8px;
    margin-left: 4px;
}

.newBotLanguageSelect {
    width: 260px;
    margin-left: auto;
    margin-right: 0;
}

.botNameInput {
    font-size: 14px;
    width: 260px;
    height: 32px;

    border: solid 1px var(--border-separator);
}

.client {
    margin-top: 16px;
}

.toggle {
    margin-top: 24px;
    border: none;
    margin-bottom: 24px;

    & > div {
        border: none;
        height: 24px;
    }

    & > div > div {
        padding-left: 0;
    }
}

.clientSelect {

    :global(.custom-select) {

        &__control {
            min-height: 32px;
            height: 32px;
        }
    }
}

.bottom {
    width: 100%;
}

.buttons {
    width: fit-content;
    margin-left: auto;
    margin-right: 0;

    * + * {
        margin-left: 12px;
    }

    & > button {
        height: 32px;
        padding: 0 10px;
    }

    &__notAdmin {
        margin-top: 24px;
    }
}

.clientValue {
    font-size: 14px;
    padding-top: 8px;
}

.newBotLanguageValue {
    font-size: 14px;
    padding-top: 8px;
    margin-left: 4px;
}
