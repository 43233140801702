.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 1680px;
    min-width: 1280px;
    overflow: hidden;
    padding: 0 16px;
    margin: 0 auto;
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 12px;
    height: 48px;
}
