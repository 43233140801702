.header {
    text-align: center;
    font-size: 16px;
    padding:24px
}
.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding:24px
}

.list {
    display: flex;
    flex-grow:1;
    align-self: stretch;
    justify-self: stretch;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 0 40px !important;
    & > div + div {
        margin-top: 12px;
    }
}

.modal {
    width: 480px;
    height: 247px;
    font-size: 14px;
    padding: 0
}