.conversationsReportDropdownContent {
  display: flex;
  flex-flow: column;
  width: 236px;
  padding: 8px;
  padding-bottom: 0;

  .tooltipWrapper {
    width: 100%;
  }

  .sendReportRequestButton {
    width: 100%;
  }
}

.conversationsReportDropdownContentWrapper {
  margin: 4px 0;
}