.header {
  font-size: 16px;
}

.title {
    font-weight: 600;
    margin-bottom: 8px;
}

.text {
    margin-bottom: 16px;
}

.spaceBottom {
    margin-bottom: 8px;
}

.saveButton {
    width: auto !important;
    min-width: 160px;
}

.toggle {
    width: 35px;
}

.toggleContainer {
    display: flex;
    gap: 14px;
}