.input__wrapper {
    position: relative;
}

.input {
    width: 100%;
    height: 72px;
    border: solid 1px var(--border-separator);
    padding: 12px;
    color: var(--main-text);
    font-size: 14px;
    outline: none;

    resize: none;
    border-radius: 4px;
    background-color: var(--white);

    &.error {
        border-color: var(--warning);
        &:focus-within, &:active {
           border-color: var(--primary);
        }
    }
}

.input__tooltip {
    font-size: 12px;
    position: absolute;
    top: -32px;
    border: solid 1px var(--border-separator);
    border-radius: 4px;
    box-shadow: 0 0 12px 0 var(--tooltip-shadow);
    background: var(--white);
    color: var(--warning);
    padding: 6px 8px;
}