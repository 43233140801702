.statisticTile {
  position: relative;
  height: 84px;
  border-radius: 4px;
  background-color: var(--white);
  border: solid 1px var(--border-separator);
  color: var(--main-text);
  display: flex;
  flex-direction: column;
  padding: 12px;
  justify-content: space-between;
}

.title {
  font-size: 14px;
}

.value {
  font-size: 14px;
  color: var(--primary)
}
