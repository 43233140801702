.mappingContainer {
    display: flex;
    align-items: center;


    padding-left: 8px;
    padding-top: 12px;
    padding-bottom: 12px;

    & + & {
        border-top: 1px solid var(--border-separator);
    }

    &:last-child {
        padding-bottom: 0;
    }
}

.remove {
    margin-left: 8px;
    display: flex;
    flex-direction: column;

    .fakeLabel {
        height: 24px;
        width: 24px;
    }

    .icon {
        height: 24px;
        width: 24px;
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
        flex-shrink: 0;
    }
}

.mappingElement {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.mappingDefinitionRow {
    display: flex;
    flex-grow: 1;
    padding-bottom: 12px;
}

.mappingInput {
    width: 100%;
    &:first-of-type {
        padding-right: 5px;
    }
    &:nth-of-type(2) {
        padding-left: 5px;
    }

    & > * {
        width: 100%;
    }
}

.addButton {
    padding-top: 12px;
}

.mappingType {
    /* delete icon offset alignment */
    margin-right: 30px;

    & > * + * {
        padding-top: 12px;
    }
}
