.area {
    font-family: Roboto, sans-serif;
    resize: none;
    width: 100%;
    height: 74px;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    font-size: 14px;
    color: var(--main-text);

    padding: 12px;
}

.httpStepBox {
    max-width: calc(100% - 32px);
}

.limit {
    margin-left: auto;

    font-size: 12px;
    color: var(--primary);
}

.urlMethodBlock {
    display: flex;
    flex-direction: row;
}

.urlBlock {
    max-width: calc(100% - 75px);
    margin-bottom: 16px;
    flex: 1 1 auto;
    padding: 0 12px 0 0;
}
.methodBlock {
    flex: 0 0 auto;
    width: 75px;
    margin-bottom: 16px;
    padding: 0;
}

.headersBlock {
    padding-right: 12px;
}

.urlInput {
    width: 100%;
}