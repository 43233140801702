.pill {
    height: 32px;
    width: 100%;
}

.select {
    margin-top: 8px;
}


.optionsContainer {
    position: absolute;
    right: 8px;
    top: -20px;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}
