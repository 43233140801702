.markdownContent {
  font-size: 14px;
  line-height: 20px;

  p {
    margin: 0 0 4px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    margin: 0 0 20px 0;
    padding-left: 32px;
    li {
      list-style-image: url(../../images/checkmark-bulletpoint.svg);
      padding-left: 4px;

      &:not(:last-child){
        padding-bottom: 8px;
      }
    }
  }

  a {
    color: var(--primary);
    text-decoration: none;
  }

  strong {
    font-weight: 500;
  }

  .muted {
    color: var(--secondary-text);
  }

  .partiallyImportedContact {
    display: block;
    margin-left: 32px;
    margin-top: 2px;
    margin-bottom: 16px;

    &:first-of-type {
      margin-bottom: 4px;
      margin-top: 4px;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;

    &.centered {
      vertical-align: middle;
      transform: translateY(-1px);
    }
  }
}