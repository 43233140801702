.container {
    display: flex;
    flex-flow: column;
    padding: 12px 0px 0px 0px;
    flex: 1 1 auto;

    &.noBottomPadding {
        padding-bottom: 0;
    }
}

.outer {
    display: flex;
    flex-direction: column;
    position: relative;
}

.input {
    resize: none;
    width: 152px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px var(--grey-3);
    background-color: var(--white);

    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: var(--grey-5);
    padding: 8px;
}

.buttonContainer {
    position: absolute;
    width: 100%;
    height: 72px;
    bottom: 0;
    background-image: linear-gradient(to bottom, var(--gradient-fade-start), var(--background-color) 50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
}

.horizontalTabsLayout, .currentTabContent {
    flex: 1 1 auto;
}

.tabsHeader {
    background: none;
    padding: 0 16px 0 4px;
  }
  