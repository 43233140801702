.entityValue {
    margin-left: 0.5rem;
    margin-top: 0.75rem;

    &:last-child {
        margin-bottom: 0.75rem;
    }
}

/* copied from AnswerBase.pcss component styles */
.answerBase {
    position: relative;
}
.answerBaseControls {
    display: flex;
    flex-direction: row;
    height: 45px;
    padding-bottom: 0.75rem;
}
.answerKeyAndReaction {
    display: flex;
    flex-direction: row;
}

.answerKey,
.answerReaction {
    width: 50%;
}
.answerKeyText {
    color: var(--main-text);
    line-height: normal;
    height: 32px;
    font-size: 14px;
    padding: 8px;
}

.globalSynonymsContainer {
    margin-bottom: 8px;
}

.disambiguationLink {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    color: var(--primary);
    cursor: pointer;
    margin-left: 2px;
    border: none;
    outline: none;
    background: none;
    padding: 0;
}

.disambiguationDescription {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    color: var(--secondary-text);
    padding-top: 0;
}

.synonym {
    background-color: var(--select-hover);
}
