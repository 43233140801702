.container {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 48px 1fr;
    border-top: 1px solid var(--border-separator);
    border-left: 1px solid var(--border-separator);

    & > div {
        border-bottom: 1px solid var(--border-separator);
        border-right: 1px solid var(--border-separator);
    }
}

.word {
    padding: 8px;
    display: flex;
}

.header {
    padding: 8px;
    display: flex;
    align-items: center;
}

.synonyms {
    display: flex;
    flex-direction: column;
}

.synonym {
    padding: 8px;
    display: flex;
    border-bottom: 1px solid var(--border-separator);
}

.removeButton {
    height: 18px;
    width: 17px;
    cursor: pointer;
    background: url('../images/trash.svg') no-repeat center;
    background-size: contain;
    margin-left: 16px;
    border: none;
    outline: none !important;
}
