.box {
  padding: 12px;
  width: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  border-radius: 4px;

  &.border {
    border: solid 1px var(--border-separator);
  }

  &.noPadding {
    padding: 0;
  }

  &.selectable {
    cursor: pointer;
  }

  &.selected {
    border: solid 1px var(--primary);
  }

  &.grey {
      background-color: var(--background-color);
  }
}
