.label {
    font-size: 14px;
    color: var(--main-text);

    display: flex;
    align-items: center;
}

.textLabel {
    margin-bottom: 8px;
    color: var(--secondary-text);
}
