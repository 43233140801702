.container {
  display: flex;
  padding: 14px 0;
}

.navigationList {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 12px 0 0;
  padding: 0;
}

.navigationItem {
  height: 100%;
}

.navLink {
  display: block;
  width: 325px;
  text-decoration: none;
  color: var(--color-text);
}

.content {
  margin-left: 12px;
  width: 100%;
}
