.container {
  text-align: center;
  margin: 64px 0;
}

.header {
  margin: 0 0 24px 0;
}

.text {
  margin: 0;
  font-ize: 18px;
  line-height: 24px;
  color: var(--secondary-text);
}

.descriptionContainer {
    margin-bottom:24px;
}
