.addButtonContainer {
    display: flex;
}

.addButton {
    display: flex;
    align-items: center;
    padding: 0 12px 0 5px;
    position: relative;
    outline: none !important;

    &.link {
        background-color: var(--white);
        border: none;
        padding: 0;
        color: var(--primary);
        height: unset;
    }

    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
}


.plusContainer {
    margin-right: 4px;
    height: 20px;
    width: 20px;
    object-fit: contain;

    .plusIcon {
        stroke: var(--white);
        transition: stroke 0.12s linear;
    }

    &.link {
        height: 16px;
        width: 16px;

        & .plusIcon {
            stroke: var(--primary);
        }
    }
}
