.columns {
    display: flex;
    justify-self: stretch;
    align-self: stretch;
    flex-direction: row;

    & > :not(:first-child) {
        margin-left: var(--spacing-containers);
    }
    width: 100%;
}

.rows {
    display: flex;
    flex-direction: column;
    justify-self: stretch;
    align-self: stretch;
    height:100%;

    & > :not(:first-child) {
        margin-top: var(--spacing-containers);
    }
}

.layout-start {
    justify-content: flex-start;
}

.layout-center {
    justify-content: center;
}

.layout-end {
    justify-content: flex-end;
}

.layout-wide {
    justify-content: space-between;
}

.layout-relaxed {
    justify-content: space-around;
}

.layout-even {
    justify-content: space-evenly;
}

.layout-fill {
    justify-content: stretch;
}
