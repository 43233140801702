.container {
    position: relative;
}


.trigger {
    border-radius: 50%;
    padding: 0;
    width: 48px;
    height: 48px;
    position: relative;
    border: none;
    outline: none !important;
    cursor: pointer;

    z-index: 2;

    & > * {
        margin-top: 1px;
    }

    margin-left: 25px;
}

.chatWindow {
    position: absolute;
    bottom: -48px;
    right: -160px;
    z-index: 9;

    display: flex;
    flex-direction: column;

    width: 400px;
    height: 95vh;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    margin: 12px;

    box-shadow: 0 0 12px 0 rgba(57,57,57,0.08);
}

.header {
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid var(--border-separator);
    display: flex;
    padding: 8px;
}

.close, .minimize {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    padding: 0;
}

.close:hover {
    .closeIcon {
        fill: var(--warning) !important;
    }
}

.minimize:hover {
    .minimizeIcon {
        fill: var(--primary) !important;
    }
}

.minimize {
    margin-left: 8px;
}

.startButton {
    margin-left: auto;
}

.messages {
    overflow: auto;
    list-style-type: none;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.botMessage {
    margin: 4px;
    padding: 4px;
    border: 1px solid var(--border-separator);

}

.line {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > :first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    & + .line {
        margin-top: 12px;
    }

    .bubble {
        font-size: 14px;
        color: var(--main-text);
        border-radius: 12px;
        padding: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .label {
        font-size: 11px;
        color: var(--secondary-text);
        margin-top: 4px;
    }

    &.human {
        .bubble {
            max-width: calc(380px - 68px);
            background-color: var(--conversation-background-odd);
            border-bottom-left-radius: 0;
        }

        .label {
            float: left;
        }
    }

    &.bot {

        > :first-child {
            flex-grow: 1;
        }

        .bubble {
            max-width: calc(380px - 100px);
            align-self: flex-end;
            background-color: var(--conversation-background-even);
            border-bottom-right-radius: 0;
        }

        .label {
            align-self: flex-end;
            float: right;
        }
    }
}

.conversation {
    margin: 0;
    width: 100%;
    min-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 12px;

    & + & {
        border-top: 1px solid var(--border-separator);

    }
}

.chipses {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px 4px 0;
    min-height: 32px;
    border-top: 1px solid var(--border-separator);
    background-color: #f8f8f8;
}

.chips {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: var(--main-text);
    line-height: normal;
    padding: 4px 8px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid;
    cursor: pointer;

    border-color: var(--select-hover);
    background-color: var(--select-hover);

    margin-top: 4px;
    margin-left: 4px;
}

.input {
    font-family: 'Roboto', sans-serif;
    resize: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 12px;
    font-size: 14px;
    color: var(--main-text);
    height: 34px;
    flex-grow: 1;
    overflow: hidden;
}

.inputArea {
    display: flex;
    border-top: 1px solid var(--border-separator);
}

.sendButton {
    border: none;
    outline: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;

    margin: 12px;
    padding: 4px;
    background-color: var(--primary);
}

.footer {
    margin-top: auto;
}

.footerText {

    border-top: 1px solid var(--border-separator);
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
}
