.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-bottom: 80px;
}

.innerWrapper {
  width: 661px;
  margin: auto;
}

.text {
  font-size: 16px;
  color: var(--secondary-text);
  margin: 0;
}

.highlighted {
  font-weight: 500;
}

.icon {
  margin-bottom: 32px;
}
