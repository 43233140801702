.headerContainer {
    display: flex;
    align-items: center;


    padding-left: 8px;
    padding-top: 12px;
    padding-bottom: 12px;

     & + & {
         border-top: 1px solid var(--border-separator);
     }

    &:last-child {
        padding-bottom: 0;
    }
}

.remove {
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin-left: 8px;
    margin-top: 24px;
    flex-shrink: 0;
}

.headerElement {
    max-width: calc(100% - 32px);
    display: flex;
    flex-grow: 1;
}

.headerElementInput {
    &:first-of-type {
        padding-right: 5px;
    }
    &:nth-of-type(2) {
        padding-left: 5px;
    }
    width: 50%;
}

.addButton {
    padding-top: 12px;
}
