.container {
    width: 550px;
    border-radius: 4px;
    background-color: white;
    padding: 16px;
    border: solid 1px var(--border-separator);

    display: flex;
    flex-direction: column;

    .row {
        margin-bottom: 24px;
    }

    .timezone {
        font-size: 14px;

        div {
            margin-bottom: 16px;
        }
    }
}
