.container {
    display: flex;
}

.stepper {

    user-select: none;
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;

    .stepperIcon {
        fill: var(--disabled);
        transition: fill 0.12s linear;
    }

    &:hover {
        .stepperIcon {
            fill: var(--primary);
            transition: fill 0.12s linear;
        }
    }
}

.value {
    height: 24px;

    border-radius: 3px;
    background-color: var(--select-hover);

    font-size: 14px;
    color: var(--primary);

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 4px;
    padding: 0 4px;
}

.disabled {
        user-select: none;
        width: 24px;
        height: 24px;
        object-fit: contain;

        .stepperIcon {
            fill: var(--disabled);
            transition: fill 0.12s linear;
            opacity: 0.3;
        }
}