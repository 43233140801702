.timeSettings {
    width: 100%;
    display: flex;

    font-size: 14px;
}

.label {
    color: var(--secondary-text);
    margin-bottom: 8px;
    font-size: 14px;
}