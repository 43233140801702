.checkbox {
  appearance: none;
  margin: 0;
  font: inherit;
  width: 18px;
  height: 18px;
  border: 1px solid var(--border-separator);
  border-radius: 0.15em;
  display: inline-grid;
  place-content: center;

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    background-color: CanvasText;
  }

  &:checked {
    border-color: var(--primary);

    &::before {
      background-color: var(--primary);
      transform: scale(1);
    }
  }
}
