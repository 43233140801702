.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;
}

.filtersSeparator {
    width: 100%;
    border-bottom: 1px solid var(--border-separator);
}

.filterBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filters {
    width: 1280px;
    padding: 24px 24px 8px 24px;
    font-family: 'Roboto', sans-serif;
    display: inline-flex;
    flex-direction: row;
}

.filtersBar {
    width: 1280px;
    min-height: 96px;
    display: flex;
    align-items: center;
    padding: 8px 0;
}

.events {
    width: 1280px;
    padding: 0 24px;
    margin: 28px auto 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    font-size: 14px;
    color: var(--disabled);
}
.eventsColumn {
    width: 25%;
}
.event {
    cursor: pointer;
    padding: 8px 0;
    transition: all 0.12s linear;

    &:hover {
        color: var(--primary);
    }

    &.active {
        color: var(--primary);

        &:hover {
            opacity: 1;
        }
    }
}

.dateFilter {
    display: flex;
    align-items: center;
    width: 214px;
    margin-right: 20px;
}
.durationFilter {
    width: 192px;
}
.phoneFilter {
    width: 180px;
}
.phraseFilter {
    flex-grow: 1;
}
.filterControls {
    * + * {
        margin-left: 12px;
    }
}
