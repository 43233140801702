:global {
    * {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        font-family: 'Roboto', sans-serif;
    }

    html,
    body,
    #root,
    main {
        height: 100%;
    }
}

.main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 57px 1fr;
    background-color: var(--background-color);
    overflow-x: auto;

    :global {
        body {
            min-width: 1366px;
        }
    }
}