.tag {
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 4px 8px;
  background-color: var(--select-hover);
  color: var(--main-text);
  border-radius: 8px;
  font-size: 12px;
}

.tagWithOnClick {
  cursor: pointer;
}

.tagTextLight {
  color: var(--white);
}

.removeIcon {
  fill: var(--primary);
}

.removeIconLight {
  fill: var(--white);
}
