.container {
    overflow-x: auto;
}

.table {
    border-spacing: 0;
    width: 100%;
}

.headTr {
    display: flex;
    border-bottom: 1px solid var(--border-separator);
    width: 100%;
}

.th {
    cursor: default;
    color: var(--primary);
    font-size: 14px;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12px;
    height: 47px;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.tr {
    display: flex;
    width: 100%;
    cursor: default;

    &:hover {
        background-color: var(--row-hover);
    }

    &.isClickable {
        cursor: pointer;
    }

    &.isExcluded {
        background-color: var(--table-excluded-background);

        &:hover {
            background-color: var(--table-excluded-background-hover);
        }
    }

    &.isActive {
        background-color: var(--table-excluded-background);

        &:hover {
            background-color: var(--table-excluded-background-hover);
        }
    }

    &:not(:last-child) {
        border-bottom: 1px solid var(--border-separator);
    }
}

.td {
    color: var(--main-text);
    font-size: 14px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 12px;
    line-height: 22px;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.container {
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    border-radius: 4px;
    width: 100%;
    padding: 0 !important;
    height: calc(100% - 12px);
}

.loadMoreContainer {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.resizer {
    position: absolute;
    top: 0;
    height: 100%;
    width: 5px;
    background: var(--white);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    right: 0;

    &:after {
        content: '';
        position: absolute;
        top: 20%;
        left: 50%;
        display: block;
        height: 60%;
        width: 1px;
        background: var(--border-separator);
        transition: ease all 0.2s;
    }

    &:hover {
        &:after {
            background: var(--primary);
        }
    }

    .isResizing {
        &:after {
            background: var(--primary);
        }
    }
}
