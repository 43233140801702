.groupHeader {
    padding: 6px 0 6px 10px;
    display: flex;
    align-items: center;
}

.active {
    border: solid 1px var(--primary);
    background-color: var(--white);
    z-index: 1001 !important;
    position: relative;
}

.groupTitle {
    font-size: 16px;
    color: var(--main-text);
}

.collapseToggle {
    border: none;
    outline: none;
    background: none;
    height: 100%;
    width: 12px;
    margin-right: 4px;
    padding: 0;
    margin-bottom: 1px;
}

.groupOptions {
    margin-left: auto;
    position: relative;
    margin-right: 9px;
}

.groupItems {
    transition: height 0.3s ease;
    overflow: hidden;
}

.expandedGroup {
    overflow: visible;
    animation: delay-overflow 0.3s linear;
}

@keyframes delay-overflow {
    from { overflow: hidden; }
}


.editNameContainer {
    z-index: 1000;
    position: relative;
    width: 100%;
}

.editNameInput {
    border: none;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    outline: none;
    width: 100%;
    background: transparent;
}

.confirmContainerPosition {
    position: absolute;
    right: -24px;
}