.list {
    border-radius: var(--section-border-radius);
    border: 1px solid var(--border-separator);
    background-color: var(--white);
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--main-text);
}

.headers.row {
    color: var(--secondary-text);
    transition: box-shadow linear 0.2s;
    align-items: center;

    >* {
        font-family: 'Roboto', sans-serif;
        padding: 2px 12px 0 12px;

        &:first-child {
            padding: 14px 12px 12px 12px;
        }
    }
}

.withShadow {
    box-shadow: 0px 4px 3px -2px var(--border-separator);
    z-index: 9;
}

.headerButton {
    background: transparent;
    border: none;
    outline: none;
    text-align: left;
    font-size: 14px;
    color: var(--main-text);

    &:focus,
    &::-moz-focus-inner {
        outline: none;
        border: none;
    }
}

.orderableHeader {
    color: var(--primary);
    cursor: pointer;

    .asc,
    .desc {
        vertical-align: middle;
        width: 10px;
        height: 6px;
        display: inline-block;
        margin-left: 6px;
    }

    .asc {
        background: url('../../images/arrow-down.svg') no-repeat center;
    }

    .desc {
        background: url('../../images/arrow-up.svg') no-repeat center;
    }
}

.eventsColumnHeader {
    display: flex;
    align-items: center;
}

.eventsLabel {
    flex-grow: 1;
}

.count {
    color: var(--main-text);
}

.refresh {
    outline: none;
    cursor: pointer;
    display: block;
    width: 24px;
    height: 24px;
    border: none;
    margin-left: 12px;
    padding: 0;
    background: none;
}

.content {
    cursor: pointer;
    transition: background-color 0.12s linear;

    &:hover {
        background-color: var(--row-hover);
    }

    &+* {
        border-top: 1px solid var(--border-separator);
    }
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;

    &.activeRow {
        background-color: var(--row-selected);

        > :nth-child(1) {
            border-left: 3px solid var(--primary);
            padding-left: 9px;
        }
    }

    > {
        * {
            padding: 14px 12px 13px 12px;
            white-space: nowrap;
            box-sizing: border-box;
        }

        :nth-child(1) {
            flex: 0 0 180px;
        }

        :nth-child(2) {
            flex: 0 0 110px;
        }

        :nth-child(3) {
            flex: 0 0 145px;
        }

        :nth-child(4) {
            flex-grow: 1;
        }

        .loading,
        .noData {
            flex: 0 1 100%;
        }
    }
}

.loading,
.noData {
    text-align: center;
    padding: 12px 12px;
}

.loadMoreRow {
    display: flex;
    justify-content: center;

    >* {
        margin: 16px 0 16px 0;
    }
}

.loadMoreButton {
    width: 130px;
}

.loadingButton {
    padding: 13px 12px 13px 12px;
    width: 130px;
}

.emptyList {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}