.verticalTabsLayout {
  display: flex;
  flex-flow: row;
  padding-top: 12px;
  padding-bottom: 16px;
  flex: 1 1 auto;
}

.tabsContainer {
  flex: 1 1 33%;
  margin-right: 16px;

  &>* {
    margin-bottom: 8px;
  }
}

.currentTabContent {
  flex: 1 1 67%;
  margin-left: 12px;
}