.labelChecked {
    font-size: 14px;
    color: var(--primary);
    padding: 0 0 0 2px;
    cursor:pointer;
}

.labelUnchecked {
    font-size: 14px;
    color: #919aaf;
    padding: 0 0 0 2px;
    cursor:pointer;
}
