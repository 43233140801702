.iconWithLabel {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    height: 32px;
}

.left {
    padding-left: 4px;
    display: flex;
    flex-flow: row;
    font-size: 14px;
    color: var(--black);
    align-items: center;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-right: 6px;
    width: 24px;
    height: 24px;
}

.icon {
    fill: var(--white);
}

.cyan {
    background-color: var(--cyan);
}

.orange {
    background-color: var(--dusty-orange);
}