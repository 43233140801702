.container {
    display: flex;
    flex-direction: column;

    & > * + * {
        margin-top: 8px;
    }

    & + & {
        margin-top: 8px;
    }
}
