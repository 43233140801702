.addBlockButton {
    border-radius: 4px;
    background-color: var(--primary);
    border: none;
    outline: none !important;
    color: var(--white);
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 12px 8px 6px;

    position: relative;

    height: 32px;
}

.addBlockContainer {
    display: flex;
    justify-content: center;
}

.plusIcon {
    margin-right: 4px;
}

.options {
    position: absolute;
    z-index: 3;
    display: flex;
    left: -85px;
    bottom: -8px;
    width: 328px;
    background-color: var(--white);
    border: solid 1px var(--border-separator);
    border-radius: 4px;
    box-shadow: 0 0 12px 0 var(--component-shadow);
}
