.container {
    display: flex;
    flex-direction: column;
}

.message {

}

.globalWordsContainer {
    display: flex;
    flex-direction: column;
}

.wordsList {
    display: flex;
    flex-direction: column;
}

.language {
    font-weight: 600;
    padding: 8px 0;
}

.wordsForLanguage {
    display: flex;
    flex-wrap: wrap;
}

.globalWordItem {
    display: flex;
    padding: 8px;
    align-items: center;

    border: 1px solid var(--grey-4);
    max-width: 150px;
    min-width: 75px;

    cursor: pointer;

    & + & {
        margin-left: 4px;
    }
}

.globalSynonymsWarning {
    margin: 8px 0;
    color: red;
    font-weight: 600;
    font-size: 18px;
}

.globalSynonymsLearning {
    display: flex;

    & > * {
        margin-right: 8px;
    }
}

.languageSelect {
    width: 100px;
}
