.blocks {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 16px;
    color: var(--main-text);

    &.fallbacks {
        height: 24px;
        display: flex;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 12px;
    }
}


.list {
    margin-top: 12px;
}

.titleWithIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
