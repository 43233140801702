.container {
  box-sizing: border-box;
  height: 128px;
  padding: 16px;
  border: 1px solid var(--border-separator);
  background: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 14px;
  color: var(--secondary-text);
}

.value {
  font-size: 32px;
  color: var(--main-text);
}

.bottomWrapper {
  display: flex;
  justify-content: space-between;
}

.pinned {
  .value {
    color: var(--primary);
  }
}

.editingPinned {
  border: solid 2px var(--primary);

  .value {
    color: var(--primary);
  }
}

.actionContainer {
  display: flex;
  align-items: center;
}
