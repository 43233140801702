.row {
  margin-top: 24px;
  justify-content: center;

  button {
    flex: 1 1 50%;

    &:only-child {
      width: 154px;
      flex: 0 0 auto;
    }
  }
}

.loginInput {
  position: relative;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;

  &:first-child {
    padding-top: 0;
  }

  &:nth-last-of-type(2) {
    padding-bottom: 0;
  }

  &>div:first-child {
    height: 16px;
  }

  input {
    padding-top: 9px;
    box-sizing: border-box;
  }

  .newPasswordError {
    margin-bottom: -22px;
  }
}

.loginDescription {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 16px;

  &:first-child {
    height: 48px;
  }

  &:last-child {
    margin: 0;
  }
}

.helpPopupIcon {
  right: 4px;
}

.registerButton {
  width: 100%;
  margin-top: 24px;
}

.checkboxContainer {
  display: inline-flex;
  margin-top: 24px;
  font-size: 14px;
}