.notPreciseAnswer {
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    padding: 12px 12px 4px 12px;

    display: flex;
    flex-direction: column;
}

.description {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    color: var(--secondary-text);
}

.notPreciseAnswerElements {
    display: flex;
    flex-wrap: wrap;
}

.answerElement {
    height: 28px;
    border-radius: 16px;
    border: solid 1px var(--primary);
    background-color: var(--white);
    font-size: 14px;
    line-height: normal;
    color: var(--primary);

    padding: 6px 12px;
    margin-right: 5px;
    margin-bottom: 8px;

    display: flex;
    align-items: center;

    cursor: pointer;

    & > * + * {
        margin-left: 6px;
    }
}

.messages {
    margin-top: 16px;

    & > div + div {
        margin-top: 24px;
    }
}
