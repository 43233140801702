.label {
    font-size: 14px;
    color: var(--secondary-text);
    margin-bottom: 16px;
}

.value {
    font-size: 14px;
    width: 100%;
    line-break: anywhere;
    display: flex;
    align-items: center;

    svg {
      margin: 0 5px;
    }

}

.copied {
    background: var(--black);
    color: var(--white);
    padding: 6px 8px;
    z-index: 2147483647;
    font-size: 12px;
    border-radius: 4px;
    transition: opacity 0.3s;
    margin-bottom: 2px;
    max-width: 225px;
    text-align: center;
    overflow: hidden;
}