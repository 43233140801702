.alert {
  box-sizing: border-box;
  display: flex;
  padding: 16px;
  border-radius: 4px;
  gap: 8px;

  &.success {
    border: 1px solid var(--alert-success-border);
    background: var(--notification-success-background);
  }

  &.error {
    border: 1px solid var(--alert-error-border);
    background: var(--notification-error-background);
  }
}

.content {
  font-size: 14px;
  color: var(--black);
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.iconContainer {
  display: flex;
  width: 24px;
  height: 24px;
}