.loader {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: space-between;
    width: 48px;
    height: 12px;
    margin: 33px auto 20px auto;
}

.dot {
    display: block;
    width: 25%;
    height: 100%;
    min-height: 4px;
    min-width: 4px;
    border-radius: 50%;
    background: var(--primary);
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
        animation-delay: -1.1s;
    }

    &:nth-child(3) {
        animation-delay: -0.9s;
    }
}

@keyframes wave {
    0%,
    60%,
    100% {
        transform: initial;
    }

    30% {
        transform: translateY(-125%);
    }
}
