.guideBox {
  position: relative;
  margin-top: 12px;
  padding: 24px;

  .closeButton {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 12px;
    right: 12px;
    padding: 0;
    background-image: url('../../images/cancel-small.svg');

    &:hover {
      .closeIcon {
        fill: var(--primary);
      }
    }

    & .closeIcon {
      transition: fill 0.12s linear;
    }
  }

  .guideBoxStep {

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    h2 {
      font-size: 16px;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 8px;
      color: var(--main-text);
      &:only-child {
        margin-bottom: 0;
      }
    }

    p {
      margin: 0;
      color: var(--secondary-text);
    }
  }
}
