.loginBox {
  position: relative;
  display: flex;
  align-items: center;

  margin-left: auto;
  margin-right: auto;

  width: var(--login-form-standard-width);
  height: 369px;

  box-sizing: border-box;
  padding: 40px 48px;

  margin-top: -80px;

  &.tallBox {
    height: 565px;
    margin-top: 110px;
  }

  h1 {
    font-weight: normal;
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 0px;
  }

  h2 {
    font-weight: normal;
    font-size: 14px;

    margin-top: 16px;
    margin-bottom: 24px;

    text-align: center;
  }
}
