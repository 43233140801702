.contactsList {
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    border-radius: 4px;
    width: 100%;
    margin-right: 16px;
    height: 100%;
    margin-bottom: 16px;

    .loadMoreContainer {
        //display: inline-block;
        display: flex;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    :global {

        .rt-table {
            overflow: visible;
        }

        .ReactTable,
        .ReactTable .rt-thead .rt-th,
        .ReactTable .rt-td {
            border: none;
        }

        .ReactTable .rt-thead .rt-th>div {
            height: 100%;
        }

        .ReactTable .rt-thead.-header {
            box-shadow: none;
            height: 47px;
        }

        .ReactTable .rt-thead .rt-tr {
            height: 47px;
            border-left: 3px solid transparent;
        }

        .ReactTable .rt-tr-group {
            cursor: pointer;
            border-left: 3px solid var(--white);

            &,
            &>.rt-tr {
                height: 40px;
            }

            &:hover {
                background-color: var(--row-hover);
            }
        }

        .ReactTable .rt-tr-group {
            border-bottom: 1px solid var(--border-separator);
            border-left: none;
        }
    }
}

.header {
    color: var(--primary);
    font-size: 14px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12px;
}

.cell {
    color: var(--main-text);
    font-size: 14px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12px;

}

.survey_complete {
    color: var(--call-green);
}

.survey_incomplete {
    color: var(--dusty-orange);
}

.cancelled {
    color: var(--warning);
}

.verticalTrack {
    position: absolute;
    left: 20px;
}

.selected {
    position: relative;

    border-left-color: var(--primary) !important;
    background-color: var(--row-selected);
}