.navbarContainer {
  margin-bottom: 16px;
}

.navbarDescription {
  color: var(--secondary-text);
  font-size: 14px;
  margin: 0;
}

.actionsContainer {
  display: flex;
  gap: 12px;
}

.pinIcon {
  padding: 0;
}

.pinIconActive {
  fill: var(--primary);
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.sectionTitle {
  font-size: 16px;
  color: var(--main-text);
  margin: 0;
}

.separator {
  border-bottom: 2px solid var(--border-separator);
  margin: 24px 0;
}