.box {
    position: relative;

    .error {
        position: absolute;
        font-size: 12px;
        color: var(--warning);
        white-space: nowrap;
        box-sizing: border-box;
        margin: 0;
        margin-bottom: -34px;
        width: 100%;
    }
}

.input {
    width: 100%;

    &.inputError {
        border-color: var(--warning);
    }
}

.inputWithIcon {
    display: flex;
    align-items: center;
}

.icon {
    position: absolute;
    display: flex;
    align-items: center;

    right: 4px;
    flex: 0 0 auto;
    height: 24px;
    width: 24px;
}
