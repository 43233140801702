.item {
    padding-left: 12px;

    height: 42px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    margin-top: 8px;

    border: solid 1px var(--border-separator);
    background-color: var(--white);
    position: relative;

    &.view {
        padding-right: 8px;
    }
    &.disabled {
        opacity: 0.3;
    }

    &.selected {
        border: solid 1px var(--primary);
    }

    &.primary {
        border: solid 1px var(--primary);
        background-color: var(--primary);
        height: 32px;
    }

    &.withPrefix {
        padding-left: 4px;
    }

    &.edit {
        cursor: auto;
        padding-right: 12px;

        background-color: var(--white);
    }

    &.hasError {
        border: solid 1px var(--warning);
    }
}

.name {
    color: var(--main-text);
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 100%;

    &.primary {
        color: var(--white);
    }
}

.itemNameContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.withPrefixContainer {
    display: flex;
    align-items: center;

    & > * + * {
        margin-left: 2px;
    }
}

.prefixViewIcon {
    fill: var(--white);
}

.prefixEditIcon {
    fill: var(--primary);
}

.nameInput {
    border: none;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    outline: none;
    padding: 8px 0;
    width: 100%;
    background: transparent;


    &.primary {
        max-height: 32px;
        padding: 7px 0;
    }
}

.first {
    width: 15px;
    margin-left: -2px;
    margin-right: 3px;
    display: flex;
    align-items: center;
}

.firstNameContainer {
    display: flex;
    align-items: center;
    width: 100%;
}

.confirmContainerPosition {
    position: absolute;
    right: 8px;
    top: 32px;

    &.primary {
        top: 22px;
    }
}

.visitors {
    position: absolute;
    bottom: -6px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 28px;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--white);
        border: 1px solid var(--border-separator);
        border-radius: 50%;
        width: 16px;
        height: 16px;
        margin-left: -4px;
        font-size: 11px;
        font-weight: 500;
        color: var(--main-text);
        text-transform: uppercase;
        user-select: none;
    }
}
