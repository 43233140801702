.search {
    width: 100%;
    padding: 16px 24px;
    border: none;
    border-bottom: 1px solid var(--border-separator);
    font-size: 16px;
    color: var(--main-text);

    top: 0;
    background: var(--white);
}