.steppers {
    display: flex;
    flex-flow: row;
    align-items: center;
    label {
        margin-right: 12px;
    }
    &>:not(label) {
        margin-right: 8px;
    }
    font-size: 14px;
    color: var(--secondary-text);
    font-weight: normal;
}