.audioMenuButton {
    width: 18px;
    height: 18px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    position: relative;

    outline: none;
}

.audioOptions {
    position: absolute;
    top: -16px;

    width: 128px;
    opacity: 0.9;
    border-radius: 4px;
    box-shadow: 0 16px 12px 0 rgba(205, 205, 205, 0.5);
    border: solid 1px #cccccc;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.closedMenu {
    display: none;
}

.audioOption {
    position: relative;
    overflow: hidden;

    cursor: pointer !important;

    height: 40px;
    box-shadow: none;
    border-radius: 0;
    display: flex;
    margin-left: 0;

    padding: 12px 16px;

    font-size: 16px;
    font-weight: normal;
    line-height: 1;
    color: #4a4a4a;

    & > span {
        cursor: pointer;
    }

    & + & {
        border-top: solid 1px #cccccc;
    }
}

.fileInput {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer !important;
    width: 100%;
    color: transparent;
    z-index: 2;

    &::-webkit-file-upload-button {
        cursor:pointer;
    }
}

.container {
    margin-left: 8px;
    display: flex;
    align-items: center;
    position: relative;
}

.addAudio {
    width: 24px;
    height: 24px;
    object-fit: contain;
    position: relative;

    &:hover {
        .addAudioIcon {
            transition: fill 0.12s linear;
            stroke: var(--primary);
        }

    }
}

.addAudioIcon {
    stroke: var(--disabled);
    transition: stroke 0.12s linear;
}

.playButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: none;
    padding: 0;
    outline: none !important;
    background: none;
    margin-left: 4px;
    z-index: 3;

    cursor: pointer;

    &:disabled {
        cursor: default;
    }

    svg {
        width: 18px;
        height: 18px;
    }
}

.optionsPosition {
    position: absolute;
    top: -9px;
    right: 19px;
    z-index: 99999;

    div {
        margin-right: 0!important;
    }

    & > div {
        margin: 0 2px;
    }
}
