.contentContainer {
  font-size: 14px;
}

.message {
  margin-top: 0;
}

.checkboxContainer {
  margin-top: 16px;
}

.checkboxOptions {
  margin-top: 10px;
  display: grid;
  row-gap: 8px;
  padding-left: 1rem;
}

.saveButton {
  text-align: right;
  width: auto;
}

.label {
  margin-left: 8px;
}
