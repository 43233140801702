.container {
  margin-right: 24px;
}

.select {
  :global(.custom-select) {

    &__control {
      width: 180px !important;
      height: 100% !important;
      min-height: 100% !important;
    }
    &__placeholder {
      opacity: 1 !important;
    }

  }
}

