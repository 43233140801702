.callbacks {
    display: flex;
    flex-direction: column;
    margin-top: 24px
}

.title {
    font-size: 16px;
    color: var(--main-text);
}

.list {
    margin-top: 12px;
}

.titleWithIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}