.background {
  background: var(--background-color);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 57px);
  overflow: scroll;
}

.content {
  max-width: 1326px;
  min-width: 1280px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 0 16px;
}
