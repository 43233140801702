.container {
    padding: 12px;
    width: 100%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    border-radius: 4px;
}

.borderContainer {
    border: solid 1px var(--border-separator);
}

.noPadding {
    padding: 0;
}

.label {
    font-size: 14px;
    color: var(--main-text);
    margin-bottom: 8px;

    &.inner {
        color: var(--secondary-text);
    }

    &.disabled {
        opacity: 0.6;
        color: var(--main-text);
    }
}

.input {
    width: 100%;
}

.inline {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    & > .label {
        margin-bottom: unset;
    }
    width: auto;
}