.container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 624px;
    height: 100%;
}

.intent {
    padding: 16px 16px 0;

    & > div + div {
        margin-top: 12px;
    }

    height: 100%;
    display: flex;
    flex-direction: column;
}

.outer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 16px 0;
    width: 624px;
}

.savableLayout {
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    margin-top: 8px;

    .childrenContainer {
        display: flex;
        flex-flow: column;
        flex: 1 1 auto;
    }
}

.intentDetailsContainer {
    display: flex;
    flex-flow: row;
}