.filterHeader {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
    align-items: flex-start;
    width: calc(100vw - 32px);
    max-width: 1648px;
    min-width: 1148px;
}

.navbar {
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    justify-content: space-between;
    padding-right: 8px;
    height: 48px;
    min-height: 48px;
    align-self: stretch;
}

.navbar__buttons {
    display: flex;
}

.navbar__button {
    margin-right: 12px;
}

.cancelContactsButtonWrapper {
    display: flex;
}

.contactsDisplay {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-bottom: 16px;
}

.buttonContainer {
    position: relative;
    margin-left: auto;
}

.addContactsDropdownMenu {
    position: absolute;
    top: 32px;
    left: auto;
    right: 0;
    min-width: 280px;
}
