.category {
    text-transform: uppercase;
    font-size: 12px;
    color: var(--placeholder-text);
    padding: 0 16px;

    &:not(:first-child) {
        padding-top: 12px;
        border-top: 1px solid var(--border-separator);
    }
}