.mainContent {
    background-color: var(--background-color);
}

.intentContent {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 352px 984px;
    color: var(--grey-5);
    justify-content: center;
}

.blockContent {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 352px 376px 608px;
    color: var(--grey-5);
    justify-content: center;
}

.errorText {
    display: flex;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    height: 500px;
    flex: 1;
}

.addBlockDescriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
}

.addBlockDescriptionButton {
    margin-top: 16px;
}


