.addIcon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}

.button {
    border-width: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px 6px 6px;
    font-size: 14px;
}
