.iconButton {
    padding: 0;
}

.conversationPreviewContainer {
    background: var(--white);
    position: absolute;
    width: 400px;
    max-height: 588px;
    overflow: hidden;
    bottom: 0;
    right: 16px;
    border-radius: var(--section-border-radius) var(--section-border-radius) 0 0;
    border: 1px solid var(--border-separator);
    border-bottom: 0;
    box-shadow: 0 0 12px 0 rgba(57,57,57,0.08);
}

.conversationPreviewControlsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    height: 40px;
    padding: 8px 12px;
    border-bottom: 1px solid var(--border-separator);
}

.conversationDetailsContainer {
    height: 548px;

    &&[data-variant="minimized"] {
        height: auto;
    }
}
