.icon {
    cursor: pointer;
    &:hover .path  {
        fill: var(--red)
    }

    & .path {
        transition: fill 0.12s linear;
    }

}
