.statistics {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  padding: 12px 16px;
  margin: 0 auto;
  min-width: 1280px;
}

.statisticsFilters {
  display: flex;
}

.chart {
  margin-top: 12px;
  margin-bottom: 24px;
  position: relative;
}

.chartAddonsContainer {
  position: absolute;
  top: 12px;
  right: 12px;
}

.sectionsContainer {
  display: flex;
  flex-direction: column;
}

.summaryStatistics {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
