.container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 1680px;
    min-width: 1280px;
    overflow: hidden;
    padding: 16px;
    margin: 0 auto;

    :global {
        .ReactTable .rt-tr-group {
            cursor: default;
        }
    }
}

.horizontalTabsLayout {
    width: auto;
}

.tabsHeader {
    width: fit-content;
    max-width: 100%;

    &>div {
        max-width: 100%;
        width: fit-content;
        overflow-x: auto;
        display: flex;
        flex-flow: row nowrap;
    }
}

.tab {
    width: auto;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
}

.currentTabContent {
    flex: 1 1 auto;
}

.tableContainer {
    --reports-filter-bar-margin: 12px;
    --reports-filter-bar-height: 50px;
    height: calc(100% - var(--reports-filter-bar-margin) - var(--reports-filter-bar-margin) - var(--reports-filter-bar-height));
    padding: 0 !important;
}

.loaderContainer {
    padding: 24px 0;
}

.rightAlignTableHeader {
    justify-content: flex-end;
}

.rightAlignTableCell {
    justify-content: center;
    align-items: flex-end;
}

:global(.rt-tr-group) {
    .statusDisplay {
        display: flex;
        gap: 4px;
    }

    .statusActions {
        display: none;

        svg {
            cursor: pointer;
        }
    }

    &:hover {
        .statusDisplay {
            display: none;
        }

        .statusActions {
            display: flex;
            gap: 4px;
        }
    }
}

.emptyReportValue:before {
    content: '\200b';
}

.actionTooltip {
    display: flex;
}

.iconButton {
    padding: 0;
}