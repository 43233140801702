.helpPopup {
  display: flex;
  align-items: center;
  height: 24px;

  div {
    bottom: 100%;
    right: 0;
  }

  svg {
    cursor: pointer;

    &:hover {
      .icon {
        fill: var(--primary);
      }
    }

    .icon {
      transition: fill 0.12s;
    }
  }
}
