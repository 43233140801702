.box {
  position: relative;
}

.value {
  position: absolute;
  color: var(--primary);
  font-size: 14px;
  right: 0;
}
