.content {
    display: flex;
    justify-content: left;
    width: 100%;
    height: calc(100% - 48px - 12px - 12px);
    padding-bottom: 12px;
}

.buttonsRow {
    width: auto;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 16px;
    margin-bottom: 12px;

    .button {
        width: var(--button-width-md);

        &:first-of-type {
            margin-right: 12px;
        }
    }
}

.leftPanel {
    & > *:first-child {
        margin-bottom: 12px;
    }
}

.rightPanel {
    display: flex;
    flex: 1;
    flex-direction: column;
}
