.addStepButton {
    height: 32px;
    border-radius: 4px;
    background-color: var(--primary);
    border: none;
    outline: none !important;
    color: var(--white);
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 12px 8px 6px;

    position: relative;
}

.addStepContainer {
    display: flex;
    justify-content: center;
    position: relative;
}

.plusIcon {
    margin-right: 4px;
}

.options {
    position: absolute;
    z-index: 3;
    display: flex;
    left: 0;
    bottom: -4px;
    width: 100%;
    animation: optionsFadeIn 150ms linear;
}

@keyframes optionsFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
