.stepBar {
    display: flex;
    flex-flow: row;
    margin-bottom: 8px;
}

.stepTypeLabel {
    display: flex;
    flex-flow: column;
    font-size: 14px;
    width: 100px;
    &>:last-child {
        color: var(--primary);
        margin-top: 2px;
    }
}

.controls {
    display: flex;
    flex-flow: row;
    flex: 1 1 auto;
    align-items: center;
    justify-content: space-between;

    &>:first-child {
        width: 218px;
        &>* {
            width: 100%;
        }
    }

    &>:nth-child(2) {
        flex: 1 1 auto;
    }

    &>*:not(:last-child) {
        display: flex;
        flex-flow: row;
        margin-right: 12px;
    }

    .deleteButton {
        display: flex;
        justify-content: flex-end;
    }
}


