.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
}

.label {
    height: 46px;
    display: flex;
    padding-left: 12px;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > * {
        padding-bottom: 12px;
        padding-top: 4px;
        padding-left: 12px;
    }
}
