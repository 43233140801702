.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 720px;
    margin: auto;
}

.text * {
    color: var(--secondary-text);
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 8px 0;
    text-align: center;
}

.icon {
    margin-bottom: 24px;
}

.small {
    width: 200px;
    height: 200px;
}
