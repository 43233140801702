.previewHeader {
    display: flex;
}

.questionHeaderButtons {
    margin-left: auto;
}

.blockName {
    font-size: 16px;
    color: var(--main-text);
}

.questions {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
}

.questionPreviewName {
    text-align: center;
}
