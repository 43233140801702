.details {
    display: flex;
    flex-direction: column;
    padding: 12px 8px 80px 16px;

}

.container {
    display: flex;
    position: relative;
}

.buttonContainer {
    position: absolute;
    width: 100%;
    height: 64px;
    bottom: 0;
    background-image: linear-gradient(to bottom, var(--gradient-fade-start), var(--background-color) 50%);
}

