.answerBase {
  position: relative;
}

.answerBaseControls {
  display: flex;
  flex-direction: row;
  height: 45px;
  padding-bottom: 12px;
}

.answerBaseButtons {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 8px 8px 0 0;
}

.answerBaseRemoveButton {
  width: 24px;
  height: 24px;
  object-fit: contain;
  cursor: pointer;
  fill: var(--disabled);

  &:hover {
    .removeIcon {
      transition: fill 0.12s linear;
      fill: var(--warning);
    }
  }
}

.answerKeyAndReaction {
  display: flex;
  flex-direction: row;
}

.answerKeyInput,
.answerReaction {
  width: 50%;
}
.globalSynonymsContainer {
  margin-bottom: 8px;
}

.disambiguationLink {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: var(--primary);
  cursor: pointer;
  margin-left: 2px;
  border: none;
  outline: none;
  background: none;
  padding: 0;
}

.disambiguationDescription {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: var(--secondary-text);
  padding-top: 0;
}

