.modalContainer {
    --item-height: 43px;
    --item-padding-h: 16px;
    --item-padding-v: 12px;
    --item-padding: var(--item-padding-v) var(--item-padding-h);
    --border-radius: 4px;

    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    width: 100%;
    height: 100%;

    display: flex;

    justify-content: center;
    align-items: flex-start;
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-color: var(--main-text);
}

.modalWindow {
    margin-top: 170px;
    width: 640px;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 32px 0 rgba(57, 57, 57, 0.11);
    background-color: var(--white);
    opacity: 1;
    border: solid 1px var(--grey-3);
    padding: 0;
    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.inputContainer {
    position: relative;
    width: 100%;
    height: 52px;
    display: flex;
    padding-left: var(--item-padding-h);
    align-items: center;
    font-size: 16px;
}

.input {
    width: 100%;

    & > input {
        border: none;
        padding: var(--item-padding-h);
        padding-left: 0;
        height: 100%;
        font-size: 16px;
        color: var(--main-text);
    }
}

.inputPrefix {
    color: var(--placeholder-text);
    padding-right: 2px;
    height: 20px;
}

.add {
    width: 100%;
    height: var(--item-height);
    font-size: 16px;
    color: var(--primary);
    cursor: pointer;
    padding: var(--item-padding);
    border-top: 1px solid var(--border-separator);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.items {
    width: 100%;
    max-height: calc(var(--item-height) * 6);
    overflow-y: auto;
}

.item {
    width: 100%;
    height: var(--item-height);
    font-size: 16px;
    color: var(--black);
    cursor: pointer;
    padding: var(--item-padding);
    border-top: 1px solid var(--border-separator);
    border-radius: 0;

    &:last-of-type {
        border-radius: 0 0 var(--border-radius) var(--border-radius);
    }

    &:hover {
        background-color: var(--select-hover);
    }
}
