.addRow {
    display: flex;
    align-items: center;
}

.addDescription {
    font-size: 14px;
    color: var(--secondary-text);
    margin-left: 12px;
}
