.header {
    background-color: var(--white);
    height: 57px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    padding: 0 16px;
    align-items: center;
    border-bottom: 1px solid var(--border-separator);

    .linksWrapper {
        height: 100%;
    }

    > div {
        position: relative;
        display: inline-flex;
        flex-direction: row;

        &:first-child {
            justify-content: flex-start;
        }
        &:last-child {
            justify-content: flex-end;
        }

        a {
            transition: all 0.12s linear;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            color: var(--disabled);
            display: flex;
            align-items: center;
            text-decoration: none;

            .svgIcon {
                fill: var(--disabled);
                transition: fill 0.12s linear;
            }

            > * + * {
                margin-left: 6px;
            }

            & + a {
                margin-left: 24px;
            }

            &.active,
            &:hover {
                color: var(--primary);

                .svgIcon {
                    fill: var(--primary);
                }
            }
        }
    }    
}

.logo {
    width: 25px;
    height: 24px;
}

.linkText {
    padding-top: 12px;
    padding-bottom: 12px;
}

.search {
    height: 30px;
    margin-left: 16px;
    display: flex;
    align-items: center;

    &__icon {
        width: 18px;
        height: 18px;
    }

    .searchInput {
        & > input {
            outline: none;
            border: none;

            text-align: left;
            padding-left: 8px;
            padding-right: 8px;
            font-size: 16px;
        }
    }
}
