.group {
    width: 100%;

    & + & {
        margin-top: 12px;
    }
}

.input {
    width: 450px;
}
