.intents {
    display: flex;
    flex-direction: column;
    margin-top: 24px
}

.intent {
    padding: 8px 16px;
    width: 128px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;

    & + & {
        margin-top: 8px;
    }


    border: solid 1px var(--grey-3);
    background-color: var(--white);
    color: var(--grey-5);
}

.title {
    font-size: 16px;
    color: var(--main-text);
}

.list {
    margin-top: 18px;
}

.unassigned {
    border-top: 1px solid var(--border-separator);
    padding-top: 12px;
    margin-top: 12px;
}

.addButton {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: var(--grey-3);
    border: none;
}


.placeholder {
    padding: 8px 16px;
    width: 320px;
    height: 40px;
    border-radius: 8px;
    margin-top: 8px;

    background-color: var(--dark-blue);
    display: flex;
    align-items: center;
}

.placeholderContent {
    width: 192px;
    height: 16px;
    border-radius: 16px;
    opacity: 0.8;
    background-color: var(--white);
}

.editableContainer {
  z-index: 1000;
}

.titleWithIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}