.moduleContainer {
  width: 340px;
  padding: 16px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px var(--border-separator);
  background-color: var(--subtle);
  text-align: left;
}

.moduleTitle {
  margin-bottom: 16px;
  font-size: 14px;
}

.blockContainer {
  position: relative;
}

.handle {
  top: 50%;
  right: 0;
}
