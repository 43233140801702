.container {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
}

.toggleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    padding-left: 12px;

    & > *:not(:first-child) {
        margin-left: 46px;
    }
}

.conditions {
    display: flex;
    flex-direction: column;
    margin: 0 12px 12px;
    gap: 12px;
}

.rootConnective, .groupConnective {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;

    span {
        white-space: nowrap;
        font-size: 14px;
    }
}

.rootConnectiveSelector, .groupConnectiveSelector {
    min-width: 122px;
}

.conditionGroup {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    background: var(--background-color);
    border-radius: 4px;
}

.conditionGroupMultiple {
    background: var(--subtle);

    .condition {
        margin-left: 24px;
        width: calc(100% - 24px);
    }
}

.addGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.groupSeparator {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    color: var(--disabled);
    text-transform: uppercase;
}

.condition {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    margin-bottom: 12px;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.conditionFields {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-right: 4px;
    gap: 12px;
    max-width: calc(100% - 53px);
}

.conditionVariable {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--secondary-text);
    font-size: 14px;
    gap: 4px;
}

.conditionOperator {
    display: flex;
    flex-direction: column;
    color: var(--secondary-text);
    font-size: 14px;
    gap: 4px;
    flex-grow: 0;
    flex-shrink: 0;
}

.conditionOperand {
    display: flex;
    flex-direction: column;
    color: var(--secondary-text);
    font-size: 14px;
    gap: 4px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;

    input {
        width: auto;
    }
}
