.question {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 12px;
    padding-left: 16px;
}

.content {
    display: flex;
    flex-flow: column;

    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 0;
    padding-top: 8px;

    & > div + div {
        margin-top: 12px;
    }
}

.tabContent {
    flex: 1 1 auto;

    & > div > div > div + div {
        margin-top: 12px;
    }
}

.header {
    height: 48px;
    min-height: 48px;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    position: relative;
}

.header__tooltip {
    font-size: 12px;
    position: absolute;
    bottom: 38px;
    left: 8px;
    border: solid 1px var(--border-separator);
    box-shadow: 0 0 12px 0 var(--tooltip-shadow);
    border-radius: 4px;
    background: inherit;
    color: var(--warning);
    padding: 6px 8px;
}

.tabs {
    display: flex;
    margin-left: 8px;
    height: 100%;
}

.tabHeaderButton {
    font-size: 16px;
    height: 100%;
    cursor: pointer;
    background: none;
    border: none;
    outline: none !important;
    padding: 0;
    transition: color 0.12s linear;

    color: var(--disabled);

    &.active {
        color: var(--primary);
    }

    &:hover {
        color: var(--primary);
    }

    & + & {
        margin-left: 16px;
    }

    &.warning {
        color: var(--warning);
    }
}

.buttons {
    margin-left: auto;
}

.innerTabs {
    max-width: fit-content;

    & > * {
        padding: 0;
    }
}

.horizontalLayoutTab {
    width: auto;
    flex: 1 1 auto;
    white-space: nowrap;
}