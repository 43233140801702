.text {
    display: flex;
    font-size: 20px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 500px;
    flex: 1;
    line-height: 32px;
    color: var(--secondary-text);
}

.mailto {
    color: var(--primary);
    text-decoration: none;
}
