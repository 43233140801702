.subtitle {

  .moduleName,
  .stepName {
    font-size: 10px;
    border: 1px solid var(--border-separator);
    padding: 4px 8px;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  }

  .moduleName {
    margin-right: 4px;
  }

  .stepName {
    margin-left: 4px;
  }
}