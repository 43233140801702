.container {
  padding: 8px;
  border: 2px solid var(--border-separator);
  border-radius: 4px;

  & + & {
    margin-top: 8px;
  }
}

.outerContainer {
  margin-top: 16px;

  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 4px solid var(--border-separator);
  border-bottom: 4px solid var(--border-separator);
}

.customLabel {
  font-size: 32px;
  color: var(--main-text);
}
