.daysAtDetailsContainer {
    display: flex;
    flex-flow: row;
    align-items: center;
    flex: 1 1 auto;

    &>* {
        margin-right: 12px;
    }

    &>:first-child {
        flex: 1 1 auto;
    }
    
    font-size: 14px;
    color: var(--secondary-text);
    font-weight: normal;
}