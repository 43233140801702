.analyticsPaletteContainerComponent {
    position: fixed;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 2147483647;
}

.inner {
    display: flex;
    flex-flow: column;
    z-index: 2147483648;
    justify-content: center;
    width: 50%;
    min-width: 500px;
    background: var(--white);
    backdrop-filter: blur(8px);
    overflow: hidden;

    border-radius: 10px;
    box-shadow:
        0px 0.7px 0.7px rgba(0, 0, 0, 0.024),
        0px 2px 2px rgba(0, 0, 0, 0.035),
        0px 4.8px 4.8px rgba(0, 0, 0, 0.046),
        0px 16px 16px rgba(0, 0, 0, 0.07);
    height: auto;
    max-height: 55%;
    min-height: 300px;
}

.scroll {
    height: 400px !important;

    &>div:first-child {
        padding: 0 0;
    }
}