.container {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.optionContainer {
    display: flex;
    align-items: flex-start;
    min-height: 60px;
    cursor: pointer;
    padding: 12px 16px;

    &:hover {
        .text, .description {
            color: var(--primary);
            transition: color 0.12s linear;
        }
        .icon {
            fill: var(--primary);
            transition: fill 0.12s linear;
        }
    }

    &.disabled {
        cursor: not-allowed;

        .icon {
            opacity: 0.3;
            fill: var(--main-text);
        }
        .text, .description {
            opacity: 0.5;
            color: var(--main-text);
        }
    }

    .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 6px;
    }

    .icon {
        width: 24px;
        height: 24px;
        object-fit: contain;
        align-self: center;
        fill: var(--main-text);
    }

    .textContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-left: 16px;
    }

    .text {
        font-size: 14px;
        color: var(--main-text);
        width: 100%;
    }

    .description {
        margin-top: 4px;
        width: 100%;
        opacity: 0.5;
        font-size: 14px;
        color: var(--main-text);
    }
}

.horizontalSeparator {
    width: 100%;
    height: 1px;
    background-color: var(--border-separator);
}
