.redirectConfig {
  display: flex;
  flex-flow: row;
  margin-bottom: 12px;

  & > *:first-child {
    flex-basis: 50%;
    margin-right: 12px;
  }
}
