.errorPopup {
  margin-bottom: 2px;
  color: var(--warning);
  bottom: 100%;

  svg {
    margin: 2px 2px -3px 2px;
  }
}

.icon {
  fill: var(--warning);
}