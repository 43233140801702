.content {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.stepLink {
  color: var(--primary);
  text-decoration: none;
}

.error {
  color: var(--red);
}

.stepEntry {
  font-weight: 500;
}

.stepLinkExpired {
  font-style: italic;
}