.toggle {
    position: relative;
    padding-left: 0;

    line-height: 24px;
}

.input {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
}

.label {
    cursor: pointer;
    user-select: none;
    height: 24px;
    margin-left: 0;
    display: inline;

    &:before {
        height: 22px;
        width: 40px;
        border-radius: 12px;

        background-color: #939598;

        border: solid 1px #939598;

        content: '';
        position: absolute;
        left: 0;
        display: inline-block;
        transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
    }

    &:after {
        height: 20px;
        width: 20px;
        box-shadow: none;

        border: solid 1px #939598;

        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        background-color: var(--white);
        transition: margin-left 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        text-align: center;
        font-weight: bold;
        content: '';
    }
}

.input {
    outline: none !important;
    position: relative;
    & + .label {
        &:after {
            margin-top: 1px;
            margin-left: 2px;
        }
    }

    &:checked + .label {
        &:after {
            content: '';
            box-shadow: none;
            margin-left: 18px;
            border: solid 1px var(--primary);
        }

        &:before {
            background-color: var(--primary);
            border-color: var(--primary);
            transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
        }
    }
}

.input {
    &:disabled + .label {
        &:after {
            content: '';
            border: solid 1px var(--grey-3);
            background: rgba(255, 255, 255, 0.75);
        }

        &:before {
            background-color: var(--grey-3);
            border-color: var(--grey-3);
            transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
        }
    }
}
