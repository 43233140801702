.modal {
    min-height: 500px;
    max-height: 90%;
    user-select: none;

}

.container {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    transform: translateY(-24px);
    margin-bottom: -38px;
}

.modalSeparator {
    position: sticky;
    top: 86px;
    margin: 0 0 10px;
    width: auto;
    border: 0;
    border-bottom: 1px solid var(--border-separator);
}

.description {
    position: sticky;
    top: 0;
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-text);
    background: var(--white);
    padding-top: 24px;
    padding-bottom: 10px;
}

.tagsToSelectEmpty {
    position: sticky;
    top: 54px;
    font-size: 12px;
    line-height: 22px;
    color: var(--placeholder-text);
    font-style: italic;
    cursor: default;
    user-select: none;
    background: var(--white);
}

.selectedTags {
    position: sticky;
    top: 54px;
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
    background: var(--white);
    padding-bottom: 10px;
}

.unselectedTags {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    align-items: flex-start;
}
