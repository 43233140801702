.container {
    flex: 1 1 auto;
    height: 48px;
    border-radius: 4px;
    background-color: white;
    padding: 12px;
    border: solid 1px var(--border-separator);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .statsWrapper {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 4px;
        font-size: 14px;

        .label {
            color: var(--disabled);
            font-size: 12px;
        }
    }

    .button {
        margin-left: 12px;
        width: var(--button-width-md);
    }
}

.ready {
    color: var(--call-green);
}

.warning {
    color: var(--dusty-orange);
}

.notReady {
    color: var(--warning);
}

.gray {
    color: var(--disabled);
}
