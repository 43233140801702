.itemContainer {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    padding: 12px;
}

.itemLabel {
    font-size: 14px;
    font-weight: normal;
    color: var(--main-text);
    margin-bottom: 8px;
}

.itemDescription {
    font-size: 14px;
    font-weight: normal;
    color: var(--secondary-text);

    margin-bottom: 12px;
}

.selectContainer {
    max-width: 260px;
}
