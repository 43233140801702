.modalContent {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  max-height: 100vh;

  .modalHeader {
    display: flex;
    flex-flow: row;
    padding: 16px;
    font-size: 16px;
    line-height: 24px;
  }

  .modalSubheader {
    position: relative;
    top: 1px;
    color: var(--disabled);
    font-size: 14px;
    font-style: italic;
    margin-left: 4px;
  }

  .modalHeaderInner {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 auto;
  }

  .modalBody {
    flex: 1 1 auto;
    padding: 24px;
    overflow-y: auto;

    &.withScrollBarMargin {
      margin-right: 14px;
    }
  }

  .modalFooter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 16px;
    gap: 10px;

    button {
      width: 160px;
    }
  }

  .modalSeparator {
    margin: 0 16px;
    width: auto;
    border: 0;
    border-bottom: 1px solid var(--border-separator);
  }
}

.modalContent::after {
  content: '';
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}