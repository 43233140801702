.userMenu.specificityLevel {
  right: -4px;
  left: auto;
  top: calc(100% + 8px);
  min-width: 324px;

  .name {
    color: var(--disabled);
  }

  .options {
    padding: 6px 0;
  }

  .option {
    border-bottom: none;
    padding: 6px 0;
    margin: 0 12px;
    height: 32px;

    &.withBorder {
      border-bottom: 1px solid var(--border-separator);
    }
  }

  .name {
    margin: 0;
  }
}

.iconButton {
  padding: 0;
}

.icon {
  fill: var(--primary);
}
