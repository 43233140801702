.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-width: 1680px;
    padding: 0 16px;
    margin: 0 auto;
    min-width: 1280px;
}

.section {
    display: flex;
    margin-top: 13px;
    > div + div {
        margin-left: 12px;
    }
}

.callSection {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-bottom: 16px;
    font-size: 14px;

    > :first-child {
        flex-grow: 1;
        margin-right: 12px;
    }
}

.filterBar {
    > div:first-child {
        margin-top: 8px;
        margin-bottom: 8px;
        display: inline-block;
        width: 10%;
    }

    > div:last-child {
        display: inline-block;
        width: 80%;
    }
}

.conversationDetailsContainer {
    width: 400px;
    border-radius: var(--section-border-radius);
    border: 1px solid var(--border-separator);
    overflow: hidden;
}
