.tags {
  &:not(:empty) {
    margin-top: 24px;
  }
}

.tagContainer {
  display: flex;
  margin-bottom: 8px;
}
