.contentContainer {
  width: 100%;
}

.formGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.label {
  display: block;
  color: var(--secondary-text);
  font-size: 14px;
  margin-bottom: 4px;
}

.input {
  width: 100%;
}

.heading {
  margin: 0 0 12px 0;
  font-size: 14px;
  font-weight: 600;
}

.headingDisclaimer {
  font-weight: 400;
}

.overrides {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.overridesItem {
  display: flex;
  gap: 12px;
}

.overrideConfig {
  flex: 1;
}

.overrideActionContainer {
  display: flex;
  align-items: center;
}

.overrideAction {
  padding: 0;
}

.overridesActions {
  display: flex;
  justify-content: flex-end;
}

.fullWidthRow{
  grid-column: 1 / span 2;
}

.formActionButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 12px;
}

.testSms {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}