.modalContainer {
    height: 244px;
    padding: 0;
}

.phoneInputLabel {
    padding: 10px 0;
}

.phoneInput {
    width: 315px;
}

.cancelButton {
    margin-right: 10px;
}