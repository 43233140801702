.historyModalContainer {
    max-height: calc(100vh - 330px);
}

.snapshot {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    padding: 0px 16px;
    margin-bottom: 12px;
    transition: all ease 0.2s;
    cursor: default;
    border-radius: 4px;
    font-size: 14px;
    color: var(--main-text);
    user-select: none;

    .snapshotRowTop, .snapshotRowBottom {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .snapshotRowTop {
        padding-top: 8px;
    }

    .snapshotRowBottom {
        height: 42px;
        gap: 48px;
    }

    .rollbackButtonsContainer {
        display: none;
        flex-flow: row nowrap;
        align-items: center;
        margin-left: auto;
    }

    .annotateButton {
        fill: var(--primary);
    }

    .rollbackButton {
        margin-left: 12px;
        height: 26px;
        padding: 4px 8px;
    }

    &:hover {
        background: var(--select-hover);

        .rollbackButtonsContainer {
            display: flex;
        }
    }

    .overwrittenBy {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .annotationLoader {
        height: 5px;
        width: 20px;
        margin: 12px 12px 13px
    }

    .annotation {
        font-weight: 600;
    }
}