.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-width: 1680px;
    padding: 0 16px;
    margin: 0 auto;
    min-width: 1280px;
}

.section {
    display: flex;
    margin: 13px 0 12px 0;
}

.callSection {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-bottom: 16px;
    font-size: 14px;

    > :first-child {
        flex-grow: 1;
        margin-right: 12px;
    }
}

.conversationDetailsContainer {
    width: 400px;
    border-radius: var(--section-border-radius);
    border: 1px solid var(--border-separator);
    overflow: hidden;
}
