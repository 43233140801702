:root {
    --word-question-color: #3eb9fc;
    --numerical-question-color: #70aa20;
    --datetime-question-color: #f4b717;
    --open-question-color: #fd6b22;
    --end-question-color: #8b572a;
    --http-step-color: #af151c;
    --set_variable-step-color: #1fafa6;
    --statement-step-color: #1e3656;
    --help_propose-step-color: #ff64ca;
    --redirect-step-color: #7157ff;
    --dusty-orange: #ff8c2f;

    --white: #ffffff;
    --black: #000000;
    --grey-1: #fafafa;
    --grey-2: #eaeaea;
    --grey-3: #cccccc;
    --grey-4: #969797;
    --grey-5: #4a4a4a;

    --turquise: #77c8cb;
    --blue: #477c9c;
    --dark-blue: #1e3656;
    --red: #ed1c24;
    --cyan: #1db9af;
    --solid-blue: #5385ff;
    --subtle: #eaf0fe;

    --action-color: #50e3c2;
    --action-hover-subtle: #eaf0fe;

    --background-color: #f2f3f5;
    --background-disabled-color: #f8f8f8;
    --primary: #3c38bc;
    --main-text: #000000;
    --secondary-text: #4a4a4a;
    --placeholder-text: #797c83;

    --scrollbar-track: #e1e1e1;
    --scrollbar-thumb: #929292;

    --pill-hover: #e5e5e5;

    --date-range-picker-focus: #3c38bc;
    --date-range-picker-border: #706dd3;
    --date-range-picker-border-secondary: #9794df;
    --date-range-background-color-secondary: #d5e1fe;

    --conversation-background-odd: #d5e1fe;
    --conversation-background-even: #dcdce5;

    --section-background-color: #ffffff;
    --border-separator: #dcdce5;
    --border-separator-events: rgba(220, 220, 229, 0.4);
    --disabled: #919aaf;
    --disabled-primary: #8a88d7;
    --disabled-secondary: #f1f1f5;

    --row-hover: #f9f9fb;
    --row-selected: #f2f3f5;
    --technical-info-event: #f94242;

    --warning: #f94242;
    --accepted: #31c051;
    --ready: #31b736;

    --call-green: #48be4d;

    --notification-success-background: #f6fcf6;
    --notification-warning-background: #fffbf4;
    --notification-error-background: #fff6f6;

    --toast-success-border: var(--call-green);
    --toast-warning-border: var(--dusty-orange);
    --toast-error-border: var(--warning);

    /* adjust color when design for success alert is provided */
    --alert-success-border: #f6fcf6;
    --alert-error-border: #f2dbdb;

    --select-hover-transparent: rgba(213, 225, 254, 0.5);
    --select-hover: #d5e1fe;
    --tooltip-shadow: rgba(0, 0, 0, 0.08);
    --component-shadow: rgba(57, 57, 57, 0.08);
    --gradient-fade-start: rgba(242, 243, 245, 0);

    --table-active-background: #e3f5e3;
    --table-active-background-hover: #c8e3c8;
    --table-selection: #ecf2ff;
    --table-excluded-background: #fff6f6;
    --table-excluded-background-hover: #ffecec;

    --variables-background-color: #ffc392;

    --icon-primary: #3c38bc;
}