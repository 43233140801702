.sentenceList {
    display: flex;
    margin-top: 4px;
    flex-direction: column;

    & > div + div {
        margin-top: 8px;
    }
}

.sentenceItem {
    display: flex;
    align-items: center;
    font-size: 14px;

    & > .deleteButton {
        margin-right: 4px;
        display: flex;
        cursor: pointer;

        &:hover {
            .removeIcon {
                transition: fill 0.12s linear;
                fill: var(--primary);
            }
        }
    }

    & > .sentence {
        color: var(--main-text);
        line-height: normal;
        padding: 4px 8px;
        box-sizing: border-box;
        margin: 0 4px 0 0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        border: 1px solid;
    }

    &.queue > .sentence {
        border-color: var(--disabled);
        background-color: var(--white);
    }

    &.talkie > .sentence {
        border-color: var(--border-separator);
        background-color: var(--border-separator);
    }

    &.local > .sentence {
        border-color: var(--select-hover);
        background-color: var(--select-hover);
    }
}
