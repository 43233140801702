.modal {
    width: 40%;
    height: fit-content;
}

.modalButtons {

    .button {
        border-width: 0;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 12px 6px 6px;
        font-size: 14px;
    }
}