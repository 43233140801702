.container {
    height: 81px;
    position: relative;   

    .errorMessage {
        width: auto;
    }
}

.icon {
    margin-top: 5px;
    height: 15px;
    width: 15px;
    cursor: pointer;
    background: url('../../images/pencil.svg') no-repeat center;
    background-size: contain;

    margin-right: 4px;
}

.name {
    font-size: 16px;
    font-weight: bold;
    color: var(--grey-5);
    max-width: 516px;
    width: 100%;

    outline: none;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 4px;


    &:not(:focus) {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        cursor: pointer !important;
    }


    &:focus {
        border: 1px solid var(--grey-3);
    }
}
