.dropdownButtonWrapper{
  position: relative;
}

.dropdownButton {
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 6px;

  svg {
    margin-right: 4px;
  }
}

.dropdownContent {
  position: absolute;
  display: flex;
  flex-flow: column;
  top: -4px;
  right: -4px;
  width: auto;
  padding: 8px;
  z-index: 20;
  box-shadow: 0 0 12px 0 var(--component-shadow);

  :global {
    animation: fadeIn 150ms linear;
  }
}