.iconButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0 4px;
  outline: none !important;

  &:hover {
    .optionIcon {
      transition: fill 0.12s linear;
      fill: var(--primary);
    }

    .warningIcon {
      fill: var(--warning);
    }

    .confirmIcon {
      fill: var(--call-green);
    }
  }
}

.optionIcon {
  fill: var(--disabled);
  transition: fill 0.12s linear;
}

.badge {
  margin-left: -8px;
}
