.statisticsChartContainer {
  background-color: var(--white);
  border: 1px solid var(--border-separator);
  border-radius: 4px;
  padding: 14px 12px 24px;

  :global {
    & .recharts-tooltip-cursor {
      stroke: var(--primary);
    }
  }
}

.chartTooltip {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);
  border: solid 1px var(--primary);
  border-radius: 4px;

  font-size: 14px;
  color: var(--grey-5);

  display: flex;
  flex-direction: column;
  line-height: normal;
  padding: 12px;
}

.tooltipRow {
  display: flex;
  flex-direction: row;
}

.tooltipRowValue {
  color: var(--main-text);
}

.chartLegend {
  display: flex;
  align-items: center;
  height: 100%;
  line-height: normal;
  padding-left: 8px;
}

.legendTitle {
  width: 170px;
  font-size: 16px;
  font-weight: normal;
  color: var(--main-text);
}

.legendItems {
  flex-grow: 1;
  display: flex;
  font-size: 16px;
  color: var(--grey-5);
}

.legendItem {
  & + & {
    margin-left: 16px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.legendIcon {
  position: relative;
  width: 10px;
  min-width: 10px;
  height: 10px;
  min-height: 10px;
  border-radius: 50%;
  margin-right: 14px;

  border: 2px solid transparent;

  background-color: var(--white);

  &::after {
    position: absolute;
    content: '';
    width: 6px;
    top: 2px;
    left: 100%;
    /*!!! DO NOT OPTIMIZE - inherit does not work in shorthand version*/
    border: 1px solid;
    border-color: inherit;
  }
}
