.container {
    display: flex;
    flex-flow: column;
    width: calc(100% - 24px);

    margin: 0 12px;

}

.stepsContainer {
    display: flex;
    flex-flow: column;
    width: 100%;
    border: solid 1px var(--border-separator);
    border-radius: 4px;
    background-color: var(--white);
    box-shadow: 0 0 12px 0 var(--component-shadow);
    padding: 0 8px;

    & > div + div {
        border-top: 1px solid var(--border-separator);
    }
}

.row {
    width: 100%;
    height: 40px;
    padding: 8px 12px;
    border-radius: 4px;
    box-shadow: 0 0 12px 0 var(--component-shadow);
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
}

.stepIcon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    align-self: center;
    fill: var(--disabled);
}

.selectedStepIcon {
    fill: var(--primary);
}

.addStepElement {
    display: flex;
    align-items: center;

    & + & {
        margin-left: 24px;
    }
}

.addStepIconButton {
    height: 24px;
    width: 24px;
    outline: none;

    &:hover {
        & .stepIcon {
            fill: var(--primary);
        }
    }
}

.optionContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 48px;
    cursor: pointer;
    padding: 12px 8px;


    &:hover {
        .text {
            color: var(--primary);
            transition: color 0.12s linear;
        }

        .icon {
            fill: var(--primary);
            transition: fill 0.12s linear;
        }
    }

    &.empty {
        cursor: default;
    }

    .iconContainer {
        height: 24px;
        text-align: center;
        margin-right: 16px;
    }

    .icon {
        width: 24px;
        height: 24px;
        object-fit: contain;
        align-self: center;
        fill: var(--main-text);
    }

    .text {
        font-size: 14px;
        color: var(--main-text);
        flex-grow: 1;
        text-align: start;
    }
}
