.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    padding: 12px;
}

.label {
    margin-bottom: 12px;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-left: 12px;
}