.horizontalTabsLayout {
  display: flex;
  flex-flow: column;
  padding-bottom: 16px;
  flex: 1 1 auto;

  hr {
    margin-bottom: 0;
  }
}

.tabsContainer {
  display: flex;
  flex-flow: row;
  padding: 4px;

  &.tabsContainerWithMore {
    width: 100%;
    overflow: visible;
  }
}

.tabsHeader {
  padding: 0;
  background: none;

  &.tabsHeaderWithMore {
    width: 100%;
    overflow: visible;
  }
}

.tab {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 4px;
  color: var(--disabled);
  font-size: 14px;
  height: 32px;
  padding: 8px 12px;
  cursor: pointer;
  white-space: nowrap;

  &.selected {
    background: var(--select-hover-transparent);
    color: var(--primary);
  }

  &:hover {
    transition: all 0.12s linear;
    color: var(--primary);

    .icon {
      transition: all 0.12s linear;
      fill: var(--primary);
    }
  }
}

.moreTabsContainer {
  position: relative;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0 8px 12px;
  height: 32px;
  width: auto;
}

.moreTabsDropdownMenu {
  width: max-content;
  max-width: 300px;
  right: -5px;
  left: auto;
  top: 32px;
}

.moreTabsDropdownMenuOptions {
  overflow-y: auto;
  max-height: 516px;
}

.moreTabsDropdownMenuOption {
  gap: 8px;
  padding: 6px 12px !important;
  color: var(--disabled);
  border: 0 !important;
}

.moreTabsDropdownMenuOptionName {
  line-height: 19px;
  margin-bottom: 0 !important;
  transition: all 0.12s linear;
}

.currentTabContent {
}

.scrollbar > div > div {
  margin-top: 12px;
}

.scrollbar > div:first-child > * {
  padding: 0 20px 0 8px;
}

.icon {
  fill: var(--disabled);

  &.selected {
    fill: var(--primary);
  }
}

.fadePadding {
  height: 62px;
}

.disabledOverlay {
  position: absolute;
  height: calc(100% - 16px);
  width: 100%;
  background: var(--background-color);
  opacity: 0.75;
  z-index: 10;
}