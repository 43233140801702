.previewHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    min-height: 18px;
    position: relative;
}

.questionHeaderButtons {
    margin-left: auto;
}

.questions {
    display: flex;
    flex-direction: column;
}

.questionPreviewContainer {
    display: flex;
    gap: 10px;
}

.questionPreviewIcon {
    display: flex;
    align-items: center;
}

.questionPreviewContent {
    flex-grow: 1;
}

.questionPreviewActions {
    position: absolute;
    right: 3px;
    top: 4px;
    z-index: 2;
}

.questionPreview {
    padding: 12px 8px 8px 12px;
    font-size: 16px;
    line-height: normal;
    color: var(--main-text);
    box-shadow: 0 2px 4px 0 rgba(229, 229, 229, 0.5);
    background-color: var(--white);
    border: solid 1px var(--border-separator);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-right: 8px;
    cursor: pointer;
    position: relative;

    &.selected {
        border: solid 1px var(--primary);
    }

    &.disabled {
        opacity: 0.9;
    }

    &.disabled::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 4px;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
        background-color: var(--border-separator);
        border-radius: 3px 0 0 3px;
    }

}

.questionPreviewName {
    padding-right: 8px;
    font-size: 14px;
    max-width: 296px;
    &.empty {
        color: var(--placeholder-text);
    }
    &.disabled {
        color: var(--disabled);
    }
}

.typeAndOptionsContainer {
    display: flex;
    align-items: center;
}

.questionTypeName {
    color: var(--grey-4);
    font-size: 14px;
    line-height: normal;

    &.disabled {
        color: var(--grey-3);
    }
}

.goToModuleIcon {
    margin-right: 6px;
    opacity: 0.6;
}
.goToBlockPreview {
    display: flex;
    align-items: center;

    & div {
        margin-right: 6px;
    }
}

.goToBlockPreviewText {
    color: var(--primary);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;

    &:hover {
        cursor: pointer;
    }
}

.conditionalQuestionIcon {
    position: absolute;
    opacity: 0.6;
    right: 26px;
    top: -5px;

}

.goToBlockPreviewPlaceholder {
    font-size: 14px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #FF8C2F;

    &.disabled {
        color: var(--grey-3);
    }
}

.disabledIcon {
    transform: rotate(-45deg);
}

.visitors {
    position: absolute;
    bottom: -6px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 28px;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--white);
        border: 1px solid var(--border-separator);
        border-radius: 50%;
        width: 16px;
        height: 16px;
        margin-left: -4px;
        font-size: 11px;
        font-weight: 500;
        color: var(--main-text);
        text-transform: uppercase;
        user-select: none;
    }
}
