.opening {
  animation: opening 0.2s ease-in-out;
}

.closing {
  animation: closing 0.2s ease-in-out;
}

@keyframes opening {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes closing {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}