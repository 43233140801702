.intentTrainingBar {
  display: flex;
  flex-flow: column;
  margin-bottom: 12px;

  &>:first-child {
    margin-bottom: 12px;
  }

  .intentTraining {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 48px;
  }

  .intentTrainingInput {
    flex: 1 1 auto;

    & > input {
      border: none;
    }
  }

  .autosuggestContainer {
    height: 100%;
  }

  :global {
    .react-autosuggest__container {
      height: 100%;
      margin: 0 12px;
    }

    .react-autosuggest__suggestions-container--open {
      width: 100%;
      top: 100%;
      max-height: 400px;
      border: none;
    }

    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      border: 1px solid var(--border-separator);
      width: 100%;
      height: 400px;
      overflow: auto;
    }

    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 10px 10px;
      font-size: 14px;
      line-height: 1.5;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: var(--select-hover);
    }
  }

  .inputWrapper {
    position: relative;
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 100%;

    .inputCaret {
      position: absolute;
      right: 12px;
    }
  }

  .intentTrainingSelect {
    height: 100%;
    flex: 1 1 auto;
    border: 1px solid var(--border-separator);
    border-top: 0;
    border-bottom: 0;
    border-radius: 0;
    padding: 12px;
  }

  .separator {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--border-separator);
    margin: 0 4px;
  }

  .linkButton {
    margin: 0 24px 0 12px;
    padding: 0;
  }

  .button {
    margin-right: 8px;
  }
}

.recognizedIntentLabel {
  flex: 0 0 auto;

  &+& {
    margin-left: 40px;
  }
}