.bg {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    margin-left: 16px;
    border: 0;
}

.scrollContainer {
    &>div {
        &>* {
            margin-bottom: 12px;

            &:first-child {
                margin-top: 12px;
            }
        }
    }
}