.try {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  position: relative;
}

.button {
  border-radius: 50%;
  padding: 0;
  width: 48px;
  height: 48px;
  position: relative;
  border: none;
  outline: none !important;
  cursor: pointer;

  background-color: var(--disabled);
  z-index: 2;

}

.buttonActive {
  background-color: var(--primary);
}

.buttonCall {
  background-color: var(--call-green);
  animation: pulse 1.05s ease-out;
  animation-iteration-count: 4;
}

@keyframes pulse {
  50% {
    transform: scale(1.2);
  }
  60% {
    transform: scale(1.2);
  }
  90% {
    transform: scale(1);
  }
}

.numberSliderContainer {
  position: absolute;
  width: 0;
  height: 48px;
  overflow: hidden;
  left: 24px;
  z-index: 1;
  transition: width 0s linear 0.3s;
}

.numberInputContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 12px 0 var(--component-shadow);
  border: solid 1px var(--border-separator);
  background-color: var(--white);
  left: -220px;

  transition: left 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
  padding: 6px 12px 6px 36px;
}

.description {
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  color: var(--secondary-text);
  margin-bottom: 4px;
}

.input {
  border: none;
  outline: none !important;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: var(--main-text);
  padding: 0;

  &::placeholder {
    color: var(--primary);
    opacity: 1;
    font-weight: normal;
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
}

.icon {
  fill: var(--white);
}

.try:hover {

  & .numberSliderContainer {
    width: 220px;
    transition: width 0s linear 0s;
  }

  & .numberInputContainer {
    left: 0;
  }
}


