.modal {
  height: 90%;
}

.downloadWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 14px;

  .downloadText {
    margin-right: 12px;
    color: var(--secondary-text);
  }

  .link {
    text-decoration: none;
  }
}

.mdContent {
  h1,
  h2,
  h3,
  li,
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 8px;
  }

  h1,
  h2,
  h3 {
    color: var(--main-text);
  }

  li,
  p {
    color: var(--secondary-text);
  }

  ol, ul {
    padding-left: 24px;
  }

  li {
    margin-bottom: 0;
  }

  h1 {
    text-align: center;
  }
}
