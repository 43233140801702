.analyticsItemComponent {
    display: block;
    width: 100%;
    padding: 16px 8px;
    font-size: 14px;
    border: 0;
    color: var(--main-text);
    background-color: var(--white);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;

    svg {
        width: 16px;
        height: 16px;
    }
}


.selected {
    cursor: pointer;
    background: var(--background-color);
}

.text {
    display: flex;
    flex-flow: column nowrap;
    gap: 4px;
}


.icon {
    fill: var(--secondary-text);
}

.name {
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.subtitle {
    color: var(--grey-4);
}