.addRedirectFromButton {
    margin-top: 10px;
}

.addRedirectFromContainer {
    margin-top: 10px;
}

.redirectFromHeaderContainer {
    display: flex;
    width: 560px;
}

.remove {
    margin-bottom: 4px;
    margin-left: 404px;
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
    align: left;
    &:hover {
        .removeIcon {
            transition: fill 0.12s linear;
            fill: var(--warning);
        }
    }
}
