.counts {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 0;
}

.item {
    width: 170px;
    align-items: center;
    padding-top: 0;
    padding-left: 0;
}
