.container {
    margin-top: 12px;
    margin-left: 16px;
    position: relative;
}

.moduleDescription {
    flex-direction: column;
    position: relative;
    margin: 6px 8px 0;

    padding: 16px 16px;
    background-color: var(--white);
    border: solid 1px var(--border-separator);
    border-radius: 4px;

    max-height: calc(100vh - 150px);
    overflow-y: scroll;


    * {
        max-width: 100%;
    }
}