.descriptionLabel {
    padding-top: 0;
}

.options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
}

.option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 50%;
}