.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    padding: 8px 12px;
    height: 50px;
}

.toggle {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;

    & > *:not(:first-child) {
        margin-left: 46px;
    }
}

.select {
    margin-left: 12px;
    flex-grow: 1;
}
