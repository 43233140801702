.container {
    position: relative;
}

.popover {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    border: solid 1px var(--grey-3);
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: 0 9px 20px 0 rgba(205, 205, 205, 0.5);
    padding: 8px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
}

.buttons {
    margin-top: 8px;
    margin-left: auto;
 & > button + button {
     margin-left: 8px;
 }
}

.button {
    width: 38px;
    height: 38px;
    border-radius: 4px;
    outline: none !important;
    cursor: pointer;
}

.confirm {
    background-color: var(--action-color);
    border: none;
}

.reject {
    border: solid 1px var(--grey-3);
    background-color: var(--white);
}

.overlay {
    position: fixed;
    top: 64px;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.4;
    background-color: var(--grey-1);
    z-index: 2;
}