.modal {
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    width: 100%;
    height: 100%;



    display: flex;

    justify-content: center;
    align-items: center;
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-color: var(--main-text);
}

.contentWrapper {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    max-width: 560px;
    width: 560px;
    height: 155px;
    opacity: 1;
    border: solid 1px var(--grey-3);
    background-color: var(--white);
    padding: 40px 24px 24px;
    border-radius: 4px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    font-size: 16px;
    text-align: center;
    color: var(--main-text);
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    margin-bottom: 8px;
}

.button {
    width: 130px;
    & + & {
        margin-left: 12px;
    }
}

