.header {
    height: 48px;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
   padding: 8px 16px;
}

.tabs {
    display: flex;
    height: 100%;
}

.tabContent {
    padding: 0 8px;
    & > div + div {
        margin-top: 12px;
    }
    margin-bottom: 16px;
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.scrollContainer {
    flex-grow: 1;
}

.tabHeaderButton {
    font-size: 16px;
    height: 100%;
    cursor: pointer;
    background: none;
    border: none;
    outline: none !important;
    padding: 0;

    color: var(--disabled);
    transition: color 0.12s linear;

    &.active {
        color: var(--primary);
    }
    &:hover {
        color: var(--primary);
    }

    & + & {
        margin-left: 16px;
    }
}

.buttons {
    margin-left: auto;
}
