.filters {
    font-family: 'Roboto', sans-serif;
    display: inline-flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 8px 8px 8px 12px;

    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    height: 48px;
}

.phraseFilter {
    flex-grow: 1;
}

.phoneFilter {
    width: 180px;
}

.durationFilter {
    width: 192px;
}

.dateFilter {
    display: flex;
    align-items: center;
    width: 210px;
    margin-right: 20px;
}