.options {
    width: 24px;
    height: 24px;

    margin-left: auto;
    cursor: pointer;

    &:hover {
        .optionsIcon {
            fill: var(--primary);
        }
    }
}

.optionsIcon {
    object-fit: contain;
    fill: var(--disabled);
    transition: fill 0.12s linear;
}
