.container {
    padding: 8px 12px 16px;
    z-index: 999;
    position: absolute;
    background: white;
    right: 0;
    top: 56px;
    border-bottom: 1px solid var(--border-separator);
    border-left: 1px solid var(--border-separator);
    border-bottom-left-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.connectionIndicator {
    border-radius: 100%;
    background-color: var(--warning);
    width: 12px;
    height: 12px;
    margin: 0 6px 12px;

    &.connected {
        background-color: var(--ready);
    }
}

.visitorsContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.visitorLink {
    text-decoration: none;
}

.visitor {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 1px solid var(--border-separator);
    background-color: var(--white);
    color: var(--black);
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;

    &.inactive {
        background-color: var(--border-separator);
        color: var(--white);
    }
}
