.tabs {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;

  column-gap: 16px;
}

.tab {
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--disabled);
  transition: color 0.12s;
  padding: 0;

  &.selected, &:hover {
    color: var(--primary);
  }
}