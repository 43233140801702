.filters {
    font-family: 'Roboto', sans-serif;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
    padding: 8px 8px 8px 12px !important;

    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    width: 100%;
    height: 48px;
}

.searchFilter {
    margin-left: 4px;
    flex-grow: 1;

    & > div {
        max-width: 340px;
    }
}

.callStatus,
.datePicker {
    margin-right: 8px;
}

.callStatus {
    width: 270px;

    :global(.custom-select) {
        &__control {
            border: 1px solid transparent !important;

            &--menu-is-open {
                border-color: var(--border-separator) !important;
            }
        }
    }
}
