.headerContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  padding-left: 8px;
  padding-top: 12px;
  padding-bottom: 12px;

  & + & {
    border-top: 1px solid var(--border-separator);
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.remove {
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-top: 24px;
  flex-shrink: 0;
}

.headerElement {
  max-width: calc(100% - 32px);
  display: flex;
  flex-grow: 1;
  gap: 10px;
}

.headerElementInput {
  width: 50%;
}

.addButton {
  padding-top: 12px;
}
