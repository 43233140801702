.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.borderContainer {
    padding: 12px;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
}

.title {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    
    word-wrap: anywhere;
    font-size: 14px;
    color: var(--main-text);
    margin-bottom: 12px;
    line-height: 24px;

    margin-top: -4px;
}

.header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
}

.label {
    font-size: 14px;
    color: var(--secondary-text);
}

.headerMenu {
    display: flex;
    margin-left: auto;
    align-items: center;
}

.conditionalButton {
    position: relative;
}
