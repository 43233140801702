.ranges {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    align-items: center;
}

.remove {
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
    fill: var(--disabled);
    margin-left: auto;

    &:hover {
        .removeIcon {
            transition: fill 0.12s linear;
            fill: var(--warning);
        }
    }
}

.removeIcon {
    fill: var(--disabled);
    transition: fill 0.12s linear;
}

.rangeRow {
    display: flex;
    flex-direction: row;
    padding-top: 12px;
}

.rangeInput {
    flex-grow: 1;
    max-width: 50%;

    &:first-of-type {
        padding-right: 7px;
    }
    &:nth-of-type(2) {
        padding-left: 7px;
    }
}

.addRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.addDescription {
    font-size: 14px;
    color: var(--secondary-text);
    margin-left: 12px;
}
