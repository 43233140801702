.wrapper {
  position: relative;

  .inputContainer {
    width: 100%;

    input {
      width: 100%;
      padding-right: 32px;

      &:focus {
        border-color: var(--border-separator);
      }
    }
  }

  .icon > div {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    display: flex;
  }
}
