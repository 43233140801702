.entity {
    &:not(.selected) {
        background: none;
        border: 1px solid transparent;
        color: var(--secondary-text);
        transition: background 0.15s;

        &:hover {
            background: var(--pill-hover);
        }
    }
}

.pendingEntity {
    margin-top: 8px;
    position: relative;
}

.entitiesList {
    padding-bottom: 80px;
}