.chipContainer {
  position: relative;
  .menu {
    max-height: 0;
    transition: max-height 0.2s ease-in-out;
    user-select: none;
  }

  &:hover .menu {
    max-height: 20px;
  }
}

.chip {
  display: flex;
  flex-flow: row;
  align-items: center;
  border-radius: 8px;
  padding: 0 8px;
  height: 24px;
  max-height: 24px;
  font-size: 12px;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  user-select: all;
  cursor: pointer;

  .editable &.verbatim,
  .editable &.expanded {
    background: var(--solid-blue);
    color: var(--white);
  }

  .editable &.lemma {
    background: var(--select-hover);
  }

  &.expanded {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    transition: max-height 0.2s ease-in-out;
    z-index: 2;
    user-select: none;
  }

  .chipContainer:not(:hover) &.expanded {
    max-height: 0;
  }

  .readOnly & {
    border: none;
    padding: 0 8px;
  }

  .readOnly &.verbatim,
  .readOnly &.expanded {
    background: var(--disabled);
    color: var(--white);
  }

  .readOnly &.lemma {
    background-color: var(--border-separator);
    color: var(--black);
  }
}
