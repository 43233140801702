.tooltipContainer {
    background: var(--black);
    color: var(--white);
    padding: 6px 8px;
    z-index: 2147483647;
    font-size: 12px;
    border-radius: 4px;
    transition: opacity 0.3s;
    margin: 2px 0;
    max-width: 225px;
    text-align: center;
}


.tooltipArrow,
.tooltipArrow::before {
    position: absolute;
    width: 6px;
    height: 6px;
    z-index: -1;
}

.tooltipArrow::before {
    content: '';
    transform: rotate(45deg);
    background: var(--black);
}

.tooltipArrow[data-placement^='top'] {
    bottom: -3px;
}

.tooltipArrow[data-placement^='bottom'] {
    top: -3px;
}

.tooltipArrow[data-placement^='left'] {
    right: -3px;
}

.tooltipArrow[data-placement^='right'] {
    left: -3px;
}
