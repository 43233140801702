.entitiesButton {
    padding-bottom: 10px;
    border: 1px solid grey;
    cursor: pointer;
}

.entitiesIntentsContainer {
    display: flex;
}

.intentsContainer {
    display: flex;
    flex-direction: column;
    height: 400px;
    flex-wrap: wrap;
    width: 600px;
}

.activeButton {
    padding-bottom: 10px;
    border: 1px solid grey;
    cursor: pointer;
    width: 150px;
}