 .events {
     padding: 8px 12px 8px 12px !important;

     &,
     .eventGroup {
         display: flex;
         flex-wrap: wrap;
         overflow: hidden;
         align-items: flex-start;
     }

     .eventGroup {
         gap: 8px;
     }

     hr {
         width: 100%;
         margin: 8px 0;
         border: 0;
         border-bottom: 1px solid var(--border-separator-events);
     }
 }

 .tagButton {
     color: var(--primary);
     border: solid 1px var(--primary);
     cursor: pointer;
 }