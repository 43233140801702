.botPicker {
    margin-left: 9px;
    margin-right: 58px;
    max-width: 240px;
    width: 240px;
    box-sizing: border-box;

    .botPickerMenu {
        background-color: white;
        padding: 4px;
    }

    &.searchable {
        .botPickerMenu {
            border-top: solid 1px var(--border-separator);
        }
        :global {

            .bot-picker__menu {
                margin-top: 10px;
                padding-top: 36px;
            }
        }
    }

    :global {
        .bot-picker__menu-list {
            overflow: hidden;
        }

        .bot-picker__control {
            cursor: pointer;

            &--is-disabled {
                background-color: white;
            }
        }

        .bot-picker__control, .bot-picker__control:hover {
            border: none;
            box-shadow: none;
            min-height: 0 !important;
        }

        .bot-picker__menu {
            top: -60%;
            border-radius: 2px;
            border: solid 1px var(--border-separator);
            z-index: 99;

            background-color: transparent;
            box-shadow: none;
            box-sizing: border-box;

            &:first-child {
                background-color: white;
            }
        }

        .bot-picker__input {
            font-size: 16px;
            input {
                max-width: 193px;
                font-family: Roboto;
            }
        }

        .bot-picker__value-container {
            padding: 0;
            color: var(--primary);
        }

        .bot-picker__option {
            font-size: 14px;
            color: var(--main-text);

            padding: 6px;
            border-radius: 4px;

            cursor: pointer;

            &--is-selected {
                background-color: var(--white);
                color: var(--main-text);
            }

            &--is-focused {
                background-color: var(--primary);
                color: var(--white);
            }
        }
    }
}

.arrow {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-left: 8px;
    background: url('../../images/arrow-down.svg') no-repeat center;
}

.searchIcon {
    margin: 0 6px 0 10px;
    width: 12px;
    height: 12px;
    background: url('../../images/search-icon.svg') no-repeat center;
    display: block;
}

.noResult {
    color: var(--disabled);
    text-align: center;
    padding: 6px;
    font-size: 14px;
}

.botNameWrapper {
    display: flex;
    align-items: center;
    padding-left: 7px;
}

.botName {
    max-width: 193px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.botInput {
    margin-top: -2px
}
