.container {
    display: flex;
    flex-direction: column;
    width: 350px;
    max-width: 350px;
    min-width: 350px;
    border-radius: var(--section-border-radius);
    border: 1px solid var(--border-separator);
    background-color: var(--white);
    height: 100%;
}

.emptyState {
    font-size: 14px;
    color: var(--main-text);
    height: 100%;
    text-align: center;
    padding: 12px 12px 12px;
}

.interactions {
    padding: 16px;
}

.interaction {
    font-size: 14px;
    color: var(--main-text);
    border-radius: 12px;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: var(--conversation-background-odd);

    &+& {
        margin-top: 16px;
    }
}