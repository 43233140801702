.savableLayout {
  .buttonsBox {
    flex-flow: row;
    padding: 8px 16px;
  }

  .buttonsBar {
    &:empty {
      min-height: 32px;
    }
    margin-left: auto;
  }

  .childrenContainer {
    padding: 12px 8px;

    &>*:not(:last-child) {
      margin-bottom: 12px;
    }

    &.noPadding {
      padding: 12px 0;
    }
  }

  .loaderBox {
    height: 360px;
    justify-content: center;
    &>* {
      margin-top: 20px;
    }
  }
}