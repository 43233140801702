.overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    opacity: 0.7;
    background-color: var(--background-color);
    z-index: 2;
}
