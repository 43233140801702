.stepsContainer {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    align-items: center;
}

.singleStep {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--disabled);
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;

    &.completed {
        background: var(--black);
        opacity: 1;
    }
}

.stepsSeparator {
    height: 1px;
    width: 34px;
    background: var(--border-separator);
}

.headerContainer {
    width: 100%;
    margin-right: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.mainContainer {
    display: flex;
    flex-flow: column nowrap;
    gap: 14px;
    min-height: 380px;
    max-height: 700px;
}

.row {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    gap: 24px;
}

.text {
    font-size: 14px;
    line-height: 20px;
}

.secondary {
    color: var(--secondary-text);
}

.field {
    display: flex;
    gap: 8px;
    align-items: center;

    &.smallGap {
        gap: 4px;
    }
}

.select {
    width: 200px;
}

.addCondition {
    margin-top: 10px;
    margin-bottom: 10px;
    align-self: flex-end;
}

.separator {
    width: auto;
    border: 0;
    border-bottom: 1px solid var(--border-separator);
    margin: 0 0 24px;
}

.filterSummary {
    margin-top: 2px;
}

.detailsContainer {
    display: flex;
    flex-flow: row nowrap;
    gap: 64px;
    justify-content: space-between;
    align-items: flex-start;
}

.excludedContacts {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
}

.excludedContactsCount {
    padding: 2px 8px;
    background: var(--border-separator);
    border-radius: 4px;
}

.listContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    padding-bottom: 12px;
    height: 312px;
    flex-shrink: 0;
}

.excludeTableContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    padding-bottom: 12px;
    height: 420px;
    flex-shrink: 0;
}

.tableContainer {
    height: 100%;
    width: 100%;
    padding: 0 !important;

    :global {
        .rt-tr-group {
            cursor: default !important;
        }
    }
}

.tableCellText {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.autoWidthButton {
    width: auto !important;
}

.textLine {
    display: inline;

    p {
        display: inline;
    }
}

.stepList {
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;
    padding-top: 10px;
}

.step {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 12px;

    span {
        font-size: 14px;
        line-height: 20px;
        color: var(--secondary-text);
    }
}
