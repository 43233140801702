:root {
    --focus-style: 0 0 2px var(--turquise), 0 0 5px var(--turquise);
    --section-border-radius: 4px;
    --spacing-containers: 12px;
    --spacing-insets: 8px;

    --button-width-md: 145px;

    --login-form-standard-width: 416px;
}
