.select {

    background-color: transparent;
    font-size: 14px;
    line-height: 1;
    color: var(--main-text);

    :global(.custom-select) {

        &__single-value {
            font-size: 14px;
            line-height: 1;
            color: var(--grey-5);
        }

        &__indicator-separator {
            display: none;
        }

        &__control {
            outline: none;
            cursor: pointer;
            box-shadow: none;
            flex-flow: row;

            border-radius: 4px;
            width: 100%;
            display: flex;
            align-items: center;

            height: 32px;
            min-height: 32px;
            transition: none;

            border: solid 1px var(--border-separator);

            &:hover {
                border-color: var(--select-hover);
            }

            &--is-disabled {
                background-color: var(--white);
                opacity: 0.6;
                cursor: not-allowed;
                pointer-events: all;

                &:hover {
                    border-color: var(--border-separator);
                }
            }

            &--menu-is-open {
                .customIndicatorOpen {
                    display: flex;
                }

                .customIndicatorClosed {
                    display: none;
                }

                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &__dropdown-indicator {
            padding: 4px;

            &, &:hover {
                color: var(--grey-4);
            }
        }

        &__menu {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            top: 100%;
            margin: 0;
            box-sizing: border-box;
            border: solid 1px var(--border-separator);
            border-top: 0;
            box-shadow: none;
            z-index: 4;
        }

        &__menu, &__menu-list {
            max-height: 256px;
        }

        &__menu-list {
            padding: 0;
        }


        &__value-container {
            flex-wrap: nowrap;
            padding: 0 8px;
            height: 100%;
        }

        &__indicators {
            padding: 4px 8px;
        }

        &__placeholder {
            opacity: 0.4;
            color: var(--main-text);
        }
    }

    &.nonBordered {
        :global(.custom-select) {
            &__control {
                height: 41px;
                min-height: 41px;
                transition: none;
                border: solid 1px transparent;
                border-bottom: none;
                padding-top: 8px;
                padding-bottom: 8px;


                &--menu-is-open {
                    height: 37px;
                    min-height: 37px;
                    padding-bottom: 4px;
                    padding-top: 8px;
                    border-color: var(--border-separator);
                    width: 250px;

                    &:hover {
                        background-color: var(--select-hover);
                    }
                }

            }

            &__value-container {
                padding: 0;
                padding-left: 8px;

            }

            &__control--menu-is-open  {
                & > :global(.custom-select__indicators) {
                    display: none;
                }
            }

        }
    }
}

.invalid {
    :global(.custom-select) {
        &__control {
            &, &:hover {
                border-color: var(--warning);
            }
        }
    }

    & .selected {
        color: var(--secondary-text);
    }
}

.icon {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: var(--select-hover);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.selected {
    display: flex;
    align-items: center;
    height: 100%;
}

.selectedLabel {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 100%;
    display: flex;
    align-items: center;
    line-height: normal;
}

.createNewLabel {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--primary);
}

.option {
    line-height: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;

    height: 32px;
    font-size: 14px;
    color: var(--main-text);
    padding: 7px 8px;

    border-top: 0;
}

.nonBordered {
    .option {
        padding: 4px 8px;

        &:last-of-type {
            padding-bottom: 8px;
        }
    }
}

.customIndicatorOpen {
    display: none;
    width: 10px;
    height: 6px;
}

.customIndicatorClosed {
    display: flex;
    width: 10px;
    height: 6px;
}

.clearIcon {
    margin-right: 8px;
}

.focusedOption {
    background-color: var(--select-hover);
}

.container {
    position: relative;
}

.tooltip {
    font-size: 12px;
    position: absolute;
    top: -32px;
    border: solid 1px var(--border-separator);
    border-radius: 4px;
    box-shadow: 0 0 12px 0 var(--tooltip-shadow);
    background: var(--white);
    color: var(--warning);
    padding: 6px 8px;
}
