.range {
  position: relative;
  width: 100%;
  padding: 10px 12px;
}

.slider {
  :global .rc-slider-rail {
    height: 4px;
    background: var(--disabled);
    border-radius: 2px;
  }

  :global .rc-slider-track {
    height: 4px;
    background: var(--primary);
    border-radius: 2px;
  }

  :global .rc-slider-handle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--primary);
    background: white;
    cursor: pointer;
    top: 2px;
    
    &:hover, &:focus, &:active {
      border: 1px solid var(--primary);
    }
  }
}
