.phoneNumbers {
  &>*:not(:last-child) {
    margin-bottom: 12px;
  }
}

.phoneNumber {
  max-width: 277px;
}

.deleteButton {
  padding: 0;
  padding-bottom: 4px;
  align-items: flex-end;
}
