.item {
    display: flex;
    align-items: center;
}

.icon {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
    margin-right: 8px;
}

.clearIcon {
    margin: 0px 20px 0 6px;
}

.inputWrapper {
    min-width: 0;
    padding: 0;
    flex: 1 1 auto;
    max-width: 264px;

    & > input {
        outline: none;
        border: none;
        font-size: 14px;
        font-family: inherit;
        min-width: 0;
        padding: 0;
        color: black;
        &::placeholder {
            color: var(--disabled);
        }
    }
}
