.container {
    width: 100%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
}

.messageBox {
    padding-top: 0;
}
