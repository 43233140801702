.optionsContainer {
  position: absolute;
  right: 8px;
  top: -20px;
}

.container {
  position: relative;
  --onlyChildWidth: 294px;
}

.select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &.onlyChild {
    width: var(--onlyChildWidth);
  }

  :global(.custom-select) {
    &__indicators {
      padding: 4px 0 4px 8px;
    }
  }
}

.selectButton {
  width: 100%;
}

.pill {
  height: 32px;

  &.onlyChild {
    width: var(--onlyChildWidth);
  }
}

.prefix {
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--dusty-orange);
  border-radius: 4px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin-right: 4px;
}

.prefixEditIcon {
  fill: var(--white);
}

.confirmContainer {
  top: 24px;
}

.editableContainer {
  border-color: var(--primary);
}

button.onlyChild {
  width: var(--onlyChildWidth);
}

button.fluid {
  width: 100%;
}