.dropdownMenu {
  position: absolute;
  top: 8px;
  left: 8px;
  
  .options {
    position: relative;
    z-index: 10;
    padding: 0;
    box-shadow: 0 0 12px 0 var(--component-shadow);

    :global {
      animation: fadeIn 150ms linear;
    }
  }

  .option {
    display: flex;
    flex-flow: row;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    padding: 16px;
    font-family: Roboto, sans-serif;

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-separator);
    }

    &:hover {
      .name, .description {
        color: var(--primary);
      }
      .iconPath {
        fill: var(--primary);
      }
    }

    .icon {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }

    .iconPath {
      fill: var(--main-text);
    }

    .name {
      text-align: left;
      margin-bottom: 4px;
    }

    .description {
      opacity: 0.5;
    }

    .content {
      display: flex;
      flex-flow: column;
      font-size: 14px;
      flex-grow: 1;
      align-items: flex-start;
    }
  }

  .activeOption {
    background: var(--select-hover-transparent);
    color: var(--primary);
  }
}