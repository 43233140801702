.definition {
  font-size: 14px;
  color: var(--secondary-text);
  flex-flow: row;
  align-items: center;

  .character {
    margin-left: 8px;
    min-width: 26px;
    justify-content: center;
    border-radius: 4px;
  }
}