.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
}

.toggle {
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
    & > *:not(:first-child) {
        margin-left: 46px;
    }
}

.description {
    margin: 4px 12px 12px 12px;
}
