.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;

  a {
    color: var(--primary);
    text-decoration: none;
  }
}

.contentWrapper {
  max-width: 661px;
  margin: auto;
  text-align: center;
}

.text {
  color: var(--secondary-text);
  font-size: 16px;
  margin: 0 0 8px 0;
}

.icon {
  margin-bottom: 24px;
}
