.footer {
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 14px;
  color: var(--secondary-text);
  border-top: 1px solid var(--border-separator);
  box-shadow:
    0px 2px 10px rgba(0, 0, 0, 0.035),
    0px 16px 80px rgba(0, 0, 0, 0.07);

  kbd {
    font-size: 12px;
    padding: 2px 4px;
    border-radius: 4px;
    background: var(--background-color);
    color: var(--placeholder-text);
    border: 1px solid var(--border-separator);
    margin: 0 4px;
  }
}