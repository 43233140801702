.row {
  display: flex;
  flex-flow: row;
  
  &>:not(:first-child) {
    margin-left: 12px;
  }

  &.verticallyCentered {
    align-items: center;
  }
}