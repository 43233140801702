.container {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: 14px;
    color: var(--secondary-text);
    position: relative;
}

.label {
    display: flex;
}

.limit {
    width: 24px;
    height: 24px;
    border-radius: 3px;
    background-color: var(--select-hover);
    font-size: 14px;
    color: var(--primary);
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.limitEdit {
    position: fixed;
    padding: 8px;
    z-index: 2;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 var(--component-shadow);
    border: solid 1px var(--border-separator);
    background-color: var(--white);
}
