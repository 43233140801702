.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--background-color);
}

.botsList {
    display: grid;
    grid-template-columns: repeat(auto-fit, 320px);
    grid-gap: 12px;
    width: 100%;
    margin-bottom: 24px;
}

.botsListContainer {
    width: 100%;
}

.filterContainer {
    display: flex;
    gap: 16px;
}

.addBotButtonContainer {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
}

.addBotButton {
    margin-left: auto;
}

.scrollBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 12px;

    --columns-count: 4;

    @media (max-width: 1340px) {
        --columns-count: 3;
    }
    @media (max-width: 1008px) {
        --columns-count: 2;
    }
    @media (max-width: 676px) {
        --columns-count: 1;
    }

    max-width: calc(var(--columns-count) * 320px + (var(--columns-count) - 1) * 12px + 2 * 12px);
}

.organizationHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.organizationName {
    font-size: 16px;
    color: var(--secondary-text);
    margin: 0;
}

.organizationSeparator {
    margin-top: 4px;
}

.manageOrganizationLink {
    color: var(--primary);
    text-decoration: none;
}