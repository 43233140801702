.answer {
    width: 100%;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    padding: 12px 4px 4px;

    & + & {
        margin-top: 12px;
    }
}
