.entityDetails {
    position: relative;
    display: flex;
    flex-flow: row;
    padding: 12px 0;
    width: 604px;
}

.savableLayoutContainer {
    padding-left: 12px;
    flex: 1 1 auto;
    max-width: calc(100% - 1px);
}

.savableLayout {
    height: 100%;

    & > div:nth-child(2) {
        height: calc(100% - 50px);
        padding-bottom: 0 !important;

        & > div:first-child {
            margin-bottom: 0;
        }

        & > div:last-child {
            height: calc(100% - 58px);
        }
    }
}