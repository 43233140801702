.rowContainer {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-left: 9px;
}

.flexContainer {
    display: flex;
}

.variableInput {
    width: 258px;
    margin-right: 10px;
}

.variableTypeSelect {
    width: 258px;
    margin-right: 8px;
}

.remove {
    margin-bottom: 4px;
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
    &:hover {
        .removeIcon {
            transition: fill 0.12s linear;
            fill: var(--warning);
        }
    }
}

.chips {
    margin: 4px 0
}

.saveButton {
    margin-top: 16px;
}

.addButton {
    width: 100px;
    margin-top: 9px;
}

