.trainingStatusRow {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  margin-bottom: 12px;

  .trainingStatusBox {
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 376px;
    height: 50px;
    padding: 8px;
  }

  .trainingStatusDescription {
    display: flex;
    flex-flow: column;
    width: 100%;
  }


  .trainingStatusLabel {
    color: var(--secondary-text);
    font-size: 12px;
    margin-bottom: 2px;
  }

  .trainingStatusValue {
    font-size: 14px;

    &.error {
      color: var(--warning);
    }
  }
}