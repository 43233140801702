.wrapper {
  position: relative;

  .removeIcon {
    position: absolute;
    height: 14px;
    width: 8px;
    margin: 0 6px;
    cursor: pointer;
    transition: fill 0.12s linear;
    fill: var(--primary);
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.error {
    .control {
      border-color: var(--warning) !important;
    }
  }

  .control {
    border-width: 1px;
    box-shadow: none;
    border-color: var(--border-separator);

    &:hover {
      border-color: var(--border-separator);
    }
  }

  .valueContainer {
    min-height: 80px;
    align-content: baseline;
  }

  .option {
    border-radius: 4px;
    padding-right: 12px;
    position: relative;
    font-size: 12px;

    &.isValid {
      background-color: var(--select-hover);
    }
  }

  input {
    font-size: 12px !important;
  }

  .input__tooltip {
    font-size: 12px;
    position: absolute;
    top: -8px;
    right: 0;
    border: solid 1px var(--border-separator);
    border-radius: 4px;
    box-shadow: 0 0 12px 0 var(--tooltip-shadow);
    background: var(--white);
    color: var(--warning);
    padding: 6px 8px;
  }
}
