.label {
  font-size: 14px;
  color: var(--secondary-text);
  margin-bottom: 4px;
}

.input {
  width: 100%;
}

.deleteButton {
  background: none;
  border: none;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.groupContainer {
  background: var(--background-color);
  padding: 12px;
  border-radius: 4px;
}

.inputsGroup {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
}

.inputsWithActions {
  max-width: calc(100% - 48px);
}

.inputContainer {
  display: flex;
  flex-direction: column;
}

.inputsWithActionsGroup {
  display: flex;
  gap: 12px;
}