.container {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
}

.toggle {
    height: auto;
    padding: 12px;
    padding-bottom: 0;

    &:only-child {
        padding-bottom: 12px;
    }
}

.input {
    min-width: 268px;
}

.inputDescription {
    font-size: 14px;
    color: var(--secondary-text);
    padding: 0;
    margin: 4px 0 4px 0;
}

.variablesSelectorContainer {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;
}

.variables {
    flex-grow: 1;
}

.copied {
    position: absolute;
    right: 12px;
    bottom: -32px;
    background: var(--black);
    color: var(--white);
    padding: 6px 8px;
    z-index: 2147483647;
    font-size: 12px;
    border-radius: 4px;
    transition: opacity 0.3s;
    margin-bottom: 2px;
    max-width: 225px;
    text-align: center;
    overflow: hidden;
}
