div.extendedLabel {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 34px;
  width: auto;

  label {
    color: var(--disabled);
    font-size: 14px;
    top: 50%;

    &.hasValue {
      font-size: 12px;
      top: 0;
    }
  }

  .value {
    font-size: 14px;

    &.primary {
      color: var(--primary);
    }
  }
}