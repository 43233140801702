.optionsPosition {
    position: absolute;
    right: 0;
    bottom: -7px;

    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & > div > * {
        margin-left: 5px;
    }
}
