.headerLink {
  transition: all 0.12s linear;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: var(--disabled);
  display: flex;
  align-items: center;
  text-decoration: none;

  .svgIcon {
    fill: var(--disabled);
    transition: fill 0.12s linear;
  }

  > svg + div,
  > i + div {
    margin-left: 6px;
  }

  & + a {
    margin-left: 24px;
  }

  &.active,
  &:hover {
    color: var(--primary);

    .svgIcon {
      fill: var(--primary);
    }
  }
}

.linkText {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
}
