.modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.modalHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    color: var(--main-text);
    width: 100%;
}

.modalContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.modalTitle {
    color: var(--main-text);
}

.newModalButton {
    position: relative;
}

.closeButton {
    margin-left: auto;
    border: none;
    outline: none;
    width: 24px;
    height: 24px;
    background-color: transparent;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../images/cancel-small.svg');
    cursor: pointer;
}

.uploadButton {
    position: relative;
}

.fileInput {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer !important;
    width: 100%;
    color: transparent;
    z-index: 2;

    &::-webkit-file-upload-button {
        cursor: pointer;
    }
}

.content {
    p {
        &:first-child {   
            margin-bottom: 16px;
        }
    }
}

.alert {
    margin-bottom: 16px;
}

.footer {
    height: 32px;
    display: flex;
    align-items: center;
    width: 100%;

    span {
        font-size: 14px;
        color: var(--grey-5);
    }

    div {
        display: flex;
        flex-grow: 1;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;

        svg {
            margin-right: 4px;
        }
    }

}