.headerContainer {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-left: 8px;
  margin-right: 16px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  width: calc(100% - 32px);
  & > * {
    margin-right: 12px;
    width: calc(50% - 16px);
    flex: 0 0 auto;
  }
}

.deleteIcon {
  height: 24px;
  margin-bottom: 5px;
}

.addButton {
    margin-top: 12px;
}

.input {
    width: 100%;
}