.linksWrapper {
    height: 100%;
    margin-right: 16px;
}

.logo {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0;
    top: 0;
    opacity: 1;
    transform: scale(1);
    transition: all 0.1s ease-in-out 0.1s;
}

.dashboardIcon {
    position: absolute;
    width: 25px;
    height: 24px;
    left: 0;
    top: 0;
    opacity: 0;
    transform: scale(0.5);
    transition: all 0.1s ease-in-out 0s;
}

.logoContainer {
    position: relative;
    width: 24px;
    height: 24px;

    &:hover {
        .logo {
            opacity: 0;
            transform: scale(0.5);
            transition-delay: 0s;
        }
        .dashboardIcon {
            opacity: 1;
            transform: scale(1);
            transition-delay: 0.1s;
        }
    }
}

.logoutButton {
    color: var(--disabled);
    font-size: 16px;
}