.tabs {
    display: inline-flex;
    flex-shrink: 1;
    flex-direction: row;
    align-items: center;
    padding: 14px 12px;
    border-radius: var(--section-border-radius);
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    box-sizing: border-box;
    height: 48px;

    a {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        color: var(--disabled);
        display: flex;
        align-items: center;
        text-decoration: none;

        > * + * {
            margin-left: 6px;
        }

        & + a {
            margin-left: 24px;
        }

    }

    button {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        color: var(--disabled);
        display: flex;
        align-items: center;
        text-decoration: none;

        > * + * {
            margin-left: 6px;
        }

        & + button {
            margin-left: 24px;
        }

    }
}

.link {

    .svgIcon {
        fill: #919AAF;
        transition: fill 0.12s linear;
    }

    &.active,
    &:hover {
        color: var(--primary);

        .svgIcon {
            fill: var(--primary);
        }
    }
}

.icon {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.linkText {
    padding-top: 12px;
    padding-bottom: 12px;
    transition: all 0.12s linear;
}
