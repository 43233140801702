.buttons {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
}

.back {
    margin-right: 12px;
}
