.dictionaryAnswers {
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    height: 100%;
}
.answer {
    display: flex;
    flex-direction: column;
}

.draggableContainer {
    padding: 0;
}

.controls {
    display: flex;
    flex-direction: row;
    height: 45px;
    padding-bottom: 12px;
}

.buttons {
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: 8px 8px 0 0;
}

.idRow .answerTextInput {
    flex-grow: 1;
    max-width: 50%;
    padding-right: 7px;
}

.remove {
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
    fill: var(--disabled);

    &:hover {
        .removeIcon {
            transition: fill 0.12s linear;
            fill: var(--warning);
        }
    }
}

.removeIcon {
    fill: var(--disabled);
    transition: fill 0.12s linear;
}

.chipsContainer {
    width: 100%;
}

.emptyPlaceholder {
    height: 100%;
    width: 100%;
}

.message {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.messageToggle {
    display: flex;
    flex-direction: row;

    & > *:not(:first-child) {
        margin-left: 16px;
    }

    margin-bottom: 8px;
}

.answerContainer {
    width: 556px;
    border-radius: 4px;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    padding: 4px;
}

.content {
    padding: 0 8px 8px;
}

.idRow {
    display: flex;
    flex-grow: 0;

    & > div + div {
        padding-left: 7px;
    }

    padding-bottom: 12px;
}

.messageRow {
    padding-bottom: 12px;
}

.addRow {
    display: flex;
    align-items: center;
}

.addDescription {
    font-size: 14px;
    color: var(--secondary-text);
    margin-left: 12px;
}

.notPreciseDescription {
    margin-top: 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    color: var(--secondary-text);
}

.notPreciseLink {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    color: var(--primary);
    cursor: pointer;
    margin-left: 2px;
    border: none;
    outline: none;
    background: none;
    padding: 0;
}

.globalSynonymsContainer {
    margin-bottom: 8px;
}
