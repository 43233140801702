.loginMessage {
  position: absolute;
  display: flex;

  flex-flow: row;
  align-items: center;

  top: -64px;
  box-sizing: border-box;
  width: var(--login-form-standard-width);
  height: 56px;
  margin-bottom: 8px;
  padding: 12px;

  font-size: 14px;

  svg {
    margin-right: 16px;
    min-width: 32px;
  }
}