.groupWrapper {
    margin-top: 8px;
}

.group {
    border-radius: 4px;
    cursor: pointer;
    border: solid 1px var(--border-separator);
    background-color: var(--white);
}

.groupContent {
    padding-left: 12px;
}

.fixMargin {
    margin-top: -8px;
}