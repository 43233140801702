.optionWrapper {
    position: relative;
    display: flex;
    padding: 0;
    height: 17px;
}

.input {
    position: absolute;
    background: transparent;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.filledValue {
    visibility: hidden;
}
.placeholder {
    color: var(--disabled);
}

.item {
    display: flex;
    align-items: center;
}

.icon {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
    margin-right: 8px;
}

.clearIcon {
    margin: 0px 20px 0 6px;
}

.inputWrapper {
    outline: none;
    border: none;
    font-size: 14px;
    font-family: inherit;
    min-width: 0;
    padding: 0;
    color: black;
    flex: 1 1 auto;
    max-width: 264px;
    &::placeholder {
        color: var(--disabled);
    }
}

